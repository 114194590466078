import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {first, map, tap} from 'rxjs/operators';
import {fromEvent, interval} from 'rxjs';
import {isArray} from 'lodash-es';

@Component({
    selector: 'input-file',
    templateUrl: './input-file.component.html',
    styleUrls: ['./input-file.component.scss'],
    host: {class: 'cell'},
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputFileComponent),
        multi: true
    }]
})
export class InputFileComponent implements ControlValueAccessor, OnInit {
    @Input() set accept(value: string | string[]) {
        this._accept = isArray(value) ? value.join(',') : value;
    }

    @Input() noMargin: boolean;

    // Beware of the use of static: true
    // #input must NOT be used within *ngIf or *ngFor in the template otherwise you should change static to false
    // and wait for ngAfterViewInit to use it
    @ViewChild('input', { static: true }) input: ElementRef;

    // Beware of the use of static: true
    // #label must NOT be used within *ngIf or *ngFor in the template otherwise you should change static to false
    // and wait for ngAfterViewInit to use it
    @ViewChild('label', { static: true }) label: ElementRef;

    @Input() text: string;
    @Input() success: boolean;

    isDisabled: boolean;
    inputText: string;

    private _accept: string;

    onChange: (files: FileList) => void;
    onTouched: (v: FileList) => void;

    public showBin: boolean;

    constructor() {
    }

    public ngOnInit(): void {
        this.inputText = this.text;

        fromEvent(this.input.nativeElement, 'change')
            .pipe(
                map(event => ((event as Event).currentTarget) as HTMLInputElement),
                map(element => element.files),
                tap(files => this.modifyInputText(files)),
                tap(files => this.showBin = files.length > 0)
            )
            .subscribe(files => this.onChange(files));
    }

    modifyInputText(files: FileList) {
        if (files && files.length) {
            this.label.nativeElement.innerText = Array.from(files)
                .map(file => file.name)
                .join(', ');
        } else {
            this.label.nativeElement.innerText = this.text;
        }
    }

    writeValue(value: any): void {
        if (value !== null) {
            this.input.nativeElement.value = '';
            this.showBin = value && value.length > 0;
            this.modifyInputText(value);
        }
    }

    registerOnChange(fn: (v: FileList) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    removeFile() {
        if (this.showBin) {
            this.onChange(null);
            this.modifyInputText(null);
            interval(100).pipe( // dirty but necessary to prevent input window opening
                first()
            ).subscribe(() => {
                this.showBin = false;
                this.success = false;
            });
        }

    }

    get accept(): string {
        return this._accept;
    }
}
