<form class="has-space" [formGroup]="form">
    <fieldset>
        <legend>{{'media.filter.title' | translate}}</legend>
        <div class="grid-x align-middle grid-padding-x grid-margin-y">
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.licenseNumber && form.controls.licenseNumber?.dirty && form.controls.licenseNumber.value?.length}">
                <input class="search cell auto force-space-right-16" type="text"
                       placeholder="{{'fleet.vehicle.licenseNumber.placeholder' | translate}}"
                       name="licenseNumber" [formControlName]="'licenseNumber'"
                       [ngModel]="criteria.licenseNumber"
                       (keyup.enter)="searchFleet()">
            </div>
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.vehicleIdentifierNumber && form.controls.vehicleIdentifierNumber?.dirty && form.controls.vehicleIdentifierNumber.value?.length}">
                <input class="search cell auto force-space-right-16" type="text"
                       placeholder="{{'fleet.vehicle.vehicleIdentifierNumber.placeholder' | translate}}"
                       name="vehicleIdentifierNumber" [formControlName]="'vehicleIdentifierNumber'"
                       [ngModel]="criteria.vehicleIdentifierNumber"
                       (keyup.enter)="searchFleet()">
            </div>
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.make && form.controls.make?.dirty && form.controls.make.value}">
                <select class="search cell auto force-space-right-16"
                        name="make" [formControlName]="'make'"
                        [ngModel]="criteria.make"
                        (ngModelChange)="onMakeFilterChange($event)"
                        (keyup.enter)="searchFleet()">
                    <option selected
                            value="undefined">{{'fleet.vehicle.make.placeholder' | translate}}</option>
                    <option *ngFor="let vehicleMake of vehicleMakesFilterObs | async; trackBy: trackByName"
                            [value]="vehicleMake.name">{{vehicleMake.name}}</option>
                </select>
            </div>

            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.model && form.controls.model?.dirty && criteria.model}">
                <select class="search cell auto force-space-right-16"
                        name="model" [formControlName]="'model'"
                        [ngModel]="criteria.model"
                        (ngModelChange)="onModelFilterChange($event)"
                        (keyup.enter)="searchFleet()">
                    <option selected
                            value="undefined">{{'fleet.vehicle.model.placeholder' | translate}}</option>
                    <option *ngFor="let vehicleModel of vehicleModelsFilterObs | async; trackBy: trackByName"
                            [value]="vehicleModel.name">
                        {{vehicleModel | fleetVehicleModelFormatter}}
                    </option>
                </select>
            </div>

            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.version && form.controls.version?.dirty && (criteria.version === '' || criteria.version !== 'initial')}">
                <select class="search cell auto force-space-right-16"
                        name="version" [formControlName]="'version'"
                        [(ngModel)]="criteria.version"
                        (keyup.enter)="searchFleet()">
                    <option selected
                            value="initial">{{'fleet.vehicle.version.placeholder' | translate}}</option>
                    <option
                        *ngFor="let vehicleVersion of vehicleVersionsFilterObs | async; trackBy: trackByVehicleVersionId"
                        [value]="vehicleVersion.version">
                        {{vehicleVersion | fleetVehicleVersionFormatter}}
                    </option>
                </select>
            </div>
            <div class="cell small-8 xxlarge-shrink">
                <button class="cell shrink button primary" type="submit" (click)="searchFleet()" [disabled]="!canEdit">
                    {{'collaborator.search.title' | translate}}
                </button>
            </div>
        </div>
        <div class="grid-x grid-padding-x grid-padding-y align-right force-space-top-12">
            <indicator class="cell small-5 force-space-right-0" [img]="'car'">
                <ng-container value>{{nbrVehicles}}</ng-container>
            </indicator>
        </div>
    </fieldset>

    <div class="small-8 grid-x grid-margin-x align-right space-top-8">
        <button class="cell shrink button secondary color add"
                [disabled]="isInsertOpened || !vehicleDataLoaded || !canEdit"
                (click)="openFleetVehicleForm()">
            {{'fleet.vehicle.add' | translate}}
        </button>
        <a *ngIf="fleetConfig?.reportingEnabled && fleetConfig?.reportingList?.includes(Reporting.FLEET)"
           [routerLink]="'/main/reporting/fleet'" class="cell shrink button secondary" type="button">
            {{'common.more' | translate}}
        </a>
    </div>

    <div class="grid-y cell auto align-center align-middle message-empty"
         *ngIf="vehiclesForm.length === 0">
        <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
        <span class="message-empty-label">{{'fleet.vehicle.noResult' | translate}}</span>
    </div>

    <div *ngIf="isInsertOpened" class="grid-y cell" formGroupName="fleetVehicleForm">
        <div class="vehicleForm grid-x">
            <h5 class="vehicleForm-edit-title required"> {{'fleet.vehicle.licenseNumber.title' | translate}}</h5>
            <input class="vehicleForm-inputNumber" formControlName="licenseNumber" type="text">
        </div>
        <div class="vehicleForm-content">
            <div *ngIf="licenseNumberCtrl.hasError('required') && licenseNumberCtrl.touched"
                 class="error note">
                {{'fleet.vehicle.licenseNumber.required' | translate}}
            </div>
            <div *ngIf="licenseNumberCtrl.hasError('notUnique') && licenseNumberCtrl.touched"
                 class="error note">
                {{'fleet.vehicle.licenseNumber.alreadyExists' | translate}}
            </div>

            <div class="grid-x small-up-4 grid-margin-x marginBottom-24">
                <ng-container formGroupName="vehicleVersion">
                    <div class="cell">
                        <label class="required">
                            <span>{{'fleet.vehicle.make.title' | translate}}</span>
                            <select formControlName="make">
                                <option disabled selected
                                        value="">{{'fleet.vehicle.make.default' | translate}}</option>
                                <option *ngFor="let vehicleMake of vehicleMakesObs | async; trackBy: trackByName"
                                        [value]="vehicleMake.name">{{vehicleMake.name}}</option>
                            </select>
                        </label>
                        <div *ngIf="!makeCtrl.valid && (makeCtrl.touched || makeCtrl.dirty)"
                             class="error note">
                            {{'fleet.vehicle.make.required' | translate}}
                        </div>
                    </div>
                    <div class="cell">
                        <label class="required">
                            <span>{{'fleet.vehicle.model.title' | translate}}</span>
                            <select formControlName="model">
                                <option disabled selected
                                        value="">{{'fleet.vehicle.model.default' | translate}}</option>
                                <option *ngFor="let vehicleModel of vehicleModelsObs | async; trackBy: trackByName"
                                        [value]="vehicleModel.name">
                                    {{vehicleModel | fleetVehicleModelFormatter}}
                                </option>
                            </select>
                        </label>
                        <div *ngIf="!vehicleVersionForm.controls.model.valid &&
                                                (vehicleVersionForm.controls.model.touched || vehicleVersionForm.controls.model.dirty)"
                             class="error note">
                            {{'fleet.vehicle.model.required' | translate}}
                        </div>
                    </div>
                    <div class="cell">
                        <label>{{'fleet.vehicle.version.title' | translate}}</label>
                        <select formControlName="version">
                            <option disabled selected
                                    value="initial">{{'fleet.vehicle.version.default' | translate}}</option>
                            <option
                                *ngFor="let vehicleVersion of vehicleVersionsObs | async; trackBy: trackByVehicleVersionId"
                                [value]="vehicleVersion.version">
                                {{vehicleVersion | fleetVehicleVersionFormatter}}
                            </option>
                        </select>
                    </div>
                </ng-container>
                <div class="cell">
                    <label>{{'fleet.vehicle.media.title' | translate}}</label>
                    <ng-container>
                        <ng-select *ngIf="mediaCanActivateExist; else noActivateMedia"
                                   formControlName="media"
                                   (search)="searchMedias($event.term)"
                                   (clear)="searchMedias(null)"
                                   [bindLabel]="'publicCode'"
                                   [placeholder]="'fleet.vehicle.media.default' | translate"
                                   [items]="mediasCanActivate">
                        </ng-select>
                        <ng-template #noActivateMedia>
                            <p class="input-read-only">{{'config.collaborator.no.media.to.activate' | translate}}</p>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
            <div class="grid-x small-up-4 grid-margin-x">
                <div class="cell space-right-8">
                    <label>{{'fleet.vehicle.carRegistrationDocument.title' | translate}}</label>
                    <input-file [accept]="fileFormats"
                                formControlName="carRegistrationFile"
                                text="{{'fleet.vehicle.carRegistrationDocument.selectFile' | translate}}">
                    </input-file>
                    <div *ngIf="carRegistrationDocumentCtrl.hasError('fileExtensionInvalid')"
                         class="error note">
                        {{'fleet.vehicle.carRegistrationDocument.fileExtensionInvalid' | translate}}
                    </div>
                </div>
                <div class="cell">
                    <label>{{'fleet.vehicle.vehicleIdentifierNumber.title' | translate}}</label>
                    <input formControlName="vehicleIdentifierNumber" type="text">
                    <div
                        *ngIf="vehicleIdentifierNumberCtrl.hasError('maxlength') && vehicleIdentifierNumberCtrl.touched"
                        class="error note">
                        {{'fleet.vehicle.vehicleIdentifierNumber.maxLength' | translate}}
                    </div>
                    <div
                        *ngIf="vehicleIdentifierNumberCtrl.hasError('notUnique') && vehicleIdentifierNumberCtrl.touched"
                        class="error note">
                        {{'fleet.vehicle.vehicleIdentifierNumber.alreadyExists' | translate}}
                    </div>
                </div>
                <div class="cell">
                    <label>{{'fleet.vehicle.vehicleUsageType.title' | translate}}</label>
                    <select formControlName="vehicleUsageType">
                        <option [value]="null" disabled
                                selected>{{'fleet.vehicle.vehicleUsageType.default' | translate}}</option>
                        <option *ngFor="let type of VehicleUsageType | keyvalue : sortNull"
                                [value]="type.value">{{('fleet.vehicle.vehicleUsageType.'
                            + type.value) | translate}}</option>
                    </select>
                </div>
                <div *ngIf="isRigeUser" class="cell">
                    <label>{{'fleet.vehicle.priority.title' | translate}}</label>
                    <label class="checkbox">
                        <input formControlName="priority" type="checkbox">
                        <span class="custom-checkbox space-top-16"></span>
                    </label>
                </div>
            </div>
            <div class="grid-x small-up-4 grid-margin-x marginBottom-24">
                <div class="cell">
                    <label>{{'fleet.vehicle.vehicleAcquisitionType.title' | translate}}</label>
                    <select formControlName="vehicleAcquisitionType">
                        <option [value]="null" disabled
                                selected>{{'fleet.vehicle.vehicleAcquisitionType.default' | translate}}</option>
                        <option *ngFor="let type of VehicleAcquisitionType | keyvalue"
                                [value]="type.value">{{('fleet.vehicle.vehicleAcquisitionType.'
                            + type.value) | translate}}</option>
                    </select>
                </div>
                <div class="cell">
                    <label>{{'fleet.vehicle.leasingPartner.title' | translate}}</label>
                    <input formControlName="leasingPartner" type="text">
                </div>
                <div class="cell">
                    <label>{{'fleet.vehicle.detail.title' | translate}}</label>
                    <input formControlName="detail" type="text">
                </div>
            </div>
            <div class="grid-x grid-margin-x fleetVehicleForm-buttons space-top-16">
                <div class="cell shrink">
                    <button (click)="cancelAddVehicle()"
                            [disabled]="addingVehicle"
                            class="button secondary loseButton">
                        {{'fleet.vehicle.cancel' | translate}}</button>
                </div>
                <div class="cell shrink">
                    <button (click)="addVehicle()"
                            [iziviaLoading]="addingVehicle"
                            [ngClass]="{disabled : fleetVehicleForm.invalid}"
                            [disabled]="fleetVehicleForm.invalid"
                            class="button validate">
                        {{'fleet.vehicle.validate' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="displayLimitMessage"
         class="info cell auto common-block force-space-bottom-0 space-top-25">
        {{'fleet.vehicle.search.limit' | translate}}
    </div>

    <br>

    <next-last-page *ngIf="!searching" [pageNumber]="pageNumber" [maxPage]="nbrPageMax"
                    (changePage)="pageChange.emit($event)">
    </next-last-page>

    <div formArrayName="vehicles">
        <div *ngIf="!searching else spinner">
            <div class="align-top align-justify space-top-25"
                 *ngFor="let vehicleForm of vehiclesForm.controls">
                <fleet-vehicle-form
                    [vehicle]="vehicleForm | asFormControl"
                    [userId]="userId"
                    [foAccountRef]="foAccountRef"
                    [vehicleMakes]="vehicleMakesObs | async"
                    [vehicleModels]="vehicleModelsObs | async"
                    [vehicleVersions]="vehicleVersionsObs | async"
                    [canEdit]="canEdit"
                    [licenseNumbers]="licenseNumbers"
                    [vehicleIdentifierNumbers]="vehicleIdentifierNumbers"
                    (removeVehicle)="deleteVehicle($event)"
                    (canEditVehicle)="canEditVehicle($event)"
                    (validateEditionVehicle)="updateVehicle($event)">
                </fleet-vehicle-form>
            </div>
        </div>
    </div>

    <next-last-page *ngIf="!searching" [pageNumber]="pageNumber" [maxPage]="nbrPageMax"
                    (changePage)="pageChange.emit($event)">
    </next-last-page>
</form>

<ng-template #spinner>
    <div class="app-loader small text-center">
        <span class="spinner small"></span>
    </div>
</ng-template>
