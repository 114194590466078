import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    FrontCurrentPersonBusinessService,
    FrontEndFleetConfig,
    FrontInvoiceHttpService, FrontPersonHttpService,
    InvoiceForm,
    InvoiceWrapper,
    isRecurringPaymentType,
    IziviaManagerNotFoundError,
    PaymentConf,
    PaymentParams,
    PaymentSource,
    PaymentType,
    User
} from 'lib-front';
import {Observable, of, switchMap, tap, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {FrontEndService} from '../../../../../services/frontEnd.service';

@Component({
    selector: 'invoice-payment',
    templateUrl: './invoice-payment.component.html',
    styleUrls: ['./invoice-payment.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class InvoicePaymentComponent implements OnInit {
    public invoiceId: string;
    public invoice: InvoiceWrapper;
    public paymentSelected: PaymentType;
    public currentPaymentConfSelected: boolean;
    public paymentConfigName: string;
    paymentConf: PaymentConf;
    private user: User;
    invoiceForm$: Observable<InvoiceForm | null>;
    filteredPaymentTypes: PaymentType[] = [];
    private fleetConfig: FrontEndFleetConfig;

    constructor(private route: ActivatedRoute,
        private invoiceHttpService: FrontInvoiceHttpService,
        private readonly currentPersonBusinessService: FrontCurrentPersonBusinessService,
        private notificationService: NotificationService,
        private readonly router: Router,
        private readonly frontEndService: FrontEndService,
        private readonly personHttpService: FrontPersonHttpService) {

    }

    public ngOnInit(): void {
        this.invoiceId = this.route.snapshot.paramMap.get('id');
        this.route.data.subscribe(data => {
            this.user = data.user.user;
            this.invoiceHttpService.findInvoiceById(this.user._id, this.invoiceId)
                .pipe(
                    tap( invoiceWrapper => {
                        this.invoice = invoiceWrapper;
                        this.filteredPaymentTypes = invoiceWrapper.paymentTypesAvailable;
                    }),
                    switchMap(_ => this.currentPersonBusinessService.foAccountManager$),
                    switchMap((foAccount) => this.invoiceHttpService.getReplacementPaymentConfByEmo(
                        foAccount.foAccountRef,
                        this.invoice.providerRef.substring('EMO/'.length)
                    )),
                    tap((paymentConfs) => {
                        paymentConfs.forEach(paymentConf => {
                            if (!this.filteredPaymentTypes.includes(paymentConf.paymentType)) {
                                this.filteredPaymentTypes.push(paymentConf.paymentType);
                            }
                        });
                    }),
                    switchMap(_ => this.frontEndService.currentFrontEndInfo$),
                    map(frontEndInfo => {
                        this.fleetConfig = frontEndInfo.fleetConfig;
                        this.filterPaymentConf();
                    }),
                    switchMap(_ => this.currentPersonBusinessService.currentDetailedPerson$)
                )
                .subscribe(person => {
                    person.customerAccounts.forEach((customerAccount) => {
                        customerAccount.paymentConfs.forEach((paymentConf) => {
                            if (!paymentConf.disabled && (paymentConf.paymentType === 'CREDIT_CARD_AUTHORIZATION' ||
                                paymentConf.paymentType === 'SEPA' || paymentConf.paymentType === 'CREDIT_CARD_RECURRING') &&
                                paymentConf.emoRef === this.invoice.providerRef.substring('EMO/'.length)
                            ) {
                                this.paymentConf = paymentConf;
                            }
                        });
                    });
                });
        });
    }

    public selectPayment(paymentType: PaymentType) {
        this.paymentSelected = paymentType;
    }

    public setPaymentConfigName(configName: string) {
        this.paymentConfigName = configName;
    }

    public configNameIsValid() {
        return !this.paymentSelected
            || !isRecurringPaymentType(this.paymentSelected)
            || this.paymentConfigName;
    }

    public startPaymentProcess() {
        if (this.currentPaymentConfSelected) {
            this.useConfiguredPaymentToPay();
        } else if (this.paymentConf && [PaymentType.SEPA, PaymentType.CREDIT_CARD_RECURRING].includes(this.paymentSelected)) {
            if (this.paymentSelected === PaymentType.SEPA) {
                this.payWithSepa();
            } else {
                this.invoiceForm$ = this.personHttpService.findDetailedPerson(this.user._id).pipe(
                    map(person => person.customerAccounts
                        .find(ca => ca.manager && ca.emoRef === this.paymentConf.emoRef)),
                    switchMap(customerAccount => {
                        if (customerAccount) {
                            return this.invoiceHttpService.replacePayment(
                                customerAccount.customerAccountRef,
                                this.paymentSelected,
                                this.paymentConf.name,
                                this.invoiceId
                            );
                        } else {
                            return throwError(new IziviaManagerNotFoundError());
                        }
                    }),
                );
            }
        } else {
            if (this.paymentSelected === 'SEPA') {
                this.payWithSepa();
            } else {
                this.payInvoice();
            }
        }
    }

    private payWithSepa() {
        this.invoiceForm$ = this.invoiceHttpService.setPaymentType(this.user._id, this.paymentSelected,
            this.invoice.providerRef.split('/')[1], this.paymentConfigName).pipe(catchError(err => {
            if (err && err.error && err.error.labelKey) {
                this.notificationService.error(err.error.labelKey);
            } else {
                this.notificationService
                    .warning('paymentStatusCause.ERROR');
            }
            return of(null);
        }));
    }

    private payInvoice() {
        const paymentParams: PaymentParams = {
            paymentType: this.paymentSelected,
            paymentConfigName: this.paymentConfigName
        };

        this.invoiceForm$ = this.invoiceHttpService.pay(this.invoiceId, paymentParams, PaymentSource.FLEET)
            .pipe(
                catchError(err => {
                    if (err && err.error && err.error.labelKey) {
                        this.notificationService.warning(err.error.labelKey);
                    } else {
                        this.notificationService
                            .warning('paymentStatusCause.ERROR');
                    }
                    return of(null);
                })
            );
    }

    selectCurrentPayment($event: PaymentConf) {
        this.currentPaymentConfSelected = !!$event;
    }

    private filterPaymentConf() {
        this.fleetConfig.refusedPaymentTypesByEmoRef[this.invoice.providerRef.substring('EMO/'.length)]
            .forEach((paymentType) => {
                if (this.filteredPaymentTypes.includes(paymentType)) {
                    this.filteredPaymentTypes.splice(this.filteredPaymentTypes.indexOf(paymentType), 1);
                }
            });
    }

    private useConfiguredPaymentToPay() {
        this.invoiceHttpService.payWithConfiguredPayment(this.invoiceId)
            .subscribe(() => this.router.navigate(['main', 'invoices']));
    }
}
