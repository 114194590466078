<form class="cell shrink grid-y login-container">
    <h2 class="login-title">{{'login.title' | translate}}</h2>

    <hr>

    <label class="cell grid-y primary label-login login" *ngIf="isAdminLogin">
        <span class="label-container">{{'login.adminLogin' | translate}}</span>
        <input class="input-login" type="email" [(ngModel)]="credentials.adminLogin" name="adminLogin">
    </label>

    <label class="cell grid-y primary label-login login">
        <span class="label-container">{{'login.login' | translate}}</span>
        <input class="input-login" type="email" [(ngModel)]="credentials.login" name="login" autocomplete="email">
    </label>

    <label class="cell grid-y secondary label-login password">
        <span
            class="label-container">{{isAdminLogin ? ('login.adminPassword' | translate) : ('login.password' | translate)}}</span>

        <show-hide-password-input
            class="input-login"
            [(ngModel)]="credentials.password"
            [autocomplete]="'current-password'"
            name="password"
        ></show-hide-password-input>
    </label>

    <div class="cell grid-x align-middle">
        <label class="cell shrink grid-y">
            <span class="remember-password">{{'login.rememberMe' | translate}}</span>
            <switch [(ngModel)]="credentials.rememberMe" name="rememberMe"></switch>
        </label>

        <div class="cell auto">&nbsp;</div>

        <div class="buttonContainer">
            <div class="cell shrink">
                <button type="submit"
                        (click)="authenticate(credentials)"
                        [iziviaLoading]="authenticating"
                        [disabled]="!credentials.login || !credentials.password"
                        class="button primary validate responsiveButton">
                    {{'login.authenticate' | translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="cell grid-x">
        <div class="cell auto displayNone">&nbsp;</div>
        <a class="cell shrink forgot-password"
           [routerLink]="'/login/forgotten-pwd'">{{'login.forgotPassword' | translate}}</a>
    </div>

    <ng-container *ngIf="allowToSignUp">
        <hr>
        <div class="cell login-note">
            {{'login.subscribe.title' | translate}} <a routerLink="/register">{{'login.subscribe.link' | translate}}</a>
        </div>
    </ng-container>
</form>

<div *ngIf="proLoginPageNotice"
     style="margin-top: 24px"
     class="cell shrink grid-y login-container"
     [innerHTML]="proLoginPageNotice">
</div>
<accept-terms-and-conditions-popup #termsAndConditionsOfUsePopup
                                   (accepted)="termsAndConditionsOfUseAccepted()"
                                   (refused)="logout()">
</accept-terms-and-conditions-popup>
<accept-pack-subscriptions-conditions #conditionsOfSubscriptionPacks
                                      (accepted)="packSubscriptionsConditionsAccepted($event)"
                                      (refused)="logout()"
>
</accept-pack-subscriptions-conditions>
