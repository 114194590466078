import {Component, OnInit} from '@angular/core';
import {
    CurrentSubscriberRolesService,
    FrontVehicleHttpService,
    Vehicle,
    VehicleSearchCriteria,
    SubscriberRoleType,
    SubscriberRoleLevel,
    buildRole
} from 'lib-front';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'fleet-configuration',
    templateUrl: './fleet-configuration.component.html',
    styleUrls: ['./fleet-configuration.component.scss'],
    host : {class : 'cell auto cell-block-y'}
})
export class FleetConfigurationComponent implements OnInit {
    public hasFleetWriteRole: boolean;

    vehicles: Array<Vehicle> = [];
    nbrVehicles: number = 0;
    pageNumber: number = 1;
    nbrPageMax: number = 0;
    searching: boolean;
    criteria: VehicleSearchCriteria = { skip: 0, limit: 10 };
    displayLimitMessage: boolean = false;

    private nbVehiclesLimit = 100;

    constructor(private readonly vehicleHttpService: FrontVehicleHttpService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.VEHICLES, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasFleetWriteRole = hasRole);
    }

    ngOnInit() {
        this.searching = true;
        this.vehicleHttpService.findVehiclesStats({})
            .subscribe((vehicleStats) => {
                this.nbrVehicles = vehicleStats.fleetVehicleSize;
                this.updateDisplayLimitMessage(this.nbrVehicles, this.nbVehiclesLimit);
                this.recomputeNbrPageMax(this.nbrVehicles);
            });
        this.search(this.criteria, 1);
    }

    public search(criteria: VehicleSearchCriteria, pageNumber) {
        criteria.skip = (pageNumber - 1) * this.criteria.limit;
        this.pageNumber = pageNumber;
        this.searchByText(criteria, false);
    }

    public searchByText(criteria: VehicleSearchCriteria, resetPage: boolean = true) {
        this.searching = true;

        if (criteria.version === 'initial') {
            criteria.version = undefined;
        }

        if (resetPage) {
            this.pageNumber = 1;
            this.vehicleHttpService.findVehiclesStats(criteria)
                .subscribe((vehicleStats) => {
                    this.nbrVehicles = vehicleStats.fleetVehicleSize;
                    this.updateDisplayLimitMessage(this.nbrVehicles, this.nbVehiclesLimit);
                    this.recomputeNbrPageMax(this.nbrVehicles);
                });
        }

        this.vehicleHttpService.findVehicles(criteria)
            .pipe(
                finalize(() => this.searching = false)
            )
            .subscribe(vehicles => this.vehicles = vehicles);
    }

    public updateNbrVehicles(updatedValue: number) {
        this.nbrVehicles = updatedValue;
    }

    public recomputeNbrPageMax(nbrVehicles: number) {
        this.nbrPageMax = nbrVehicles > this.criteria.limit ?
            Math.min(Math.ceil(nbrVehicles / this.criteria.limit), 10) : 1;
    }

    private updateDisplayLimitMessage(nbOfVehicles: number, nbOfVehiclesLimit: number) {
        if (nbOfVehicles > nbOfVehiclesLimit) {
            this.displayLimitMessage = true;
        } else {
            this.displayLimitMessage = false;
        }
    }
}
