import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    ACCEPTED_FILE_FORMATS,
    FrontPersonHttpService,
    FrontUploadHttpService,
    FrontVehicleVersionHttpService,
    FrontPersonPortalContextService,
    Vehicle,
    VehicleAcquisitionType,
    VehicleMakeDto,
    VehicleModelDto,
    VehicleUsageType,
    VehicleVersionDto,
    FrontMediaHttpService, MediaPublicCodeDto
} from 'lib-front';
import {debounceTime, shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {NotificationService} from '../../services/utils/notification.service';
import {CollapsibleCardDetailComponent} from '../collapse-card-detail/collapsible-card-detail.component';
import {Router} from '@angular/router';
import {FleetVehicleUtils} from '../fleetVehicleUtils';
import { carRegistrationFileExtensionValidator } from '../../directives/validators/carRegistrationFileExtensionValidator';
import {vehicleUpdateUniquenessValidator} from '../../directives/validators/vehicleUpdateUniquenessValidator';

@Component({
    selector: 'fleet-vehicle-form',
    templateUrl: './fleet-vehicle-form.component.html',
    styleUrls: ['./fleet-vehicle-form.component.scss'],
})
export class FleetVehicleFormComponent implements OnInit, OnDestroy {
    private readonly baseUrl: string = '/main/configuration/fleet';

    @Input() vehicle: UntypedFormControl;
    @Input() vehicleMakes: VehicleMakeDto[];
    @Input() vehicleModels: VehicleModelDto[];
    @Input() vehicleVersions: VehicleVersionDto[];
    @Input() canEdit: boolean;
    @Input() licenseNumbers;
    @Input() vehicleIdentifierNumbers;
    @Input() userId: string;
    @Input() foAccountRef: string;

    @ViewChild('collapsibleCard') collapsibleCard: CollapsibleCardDetailComponent;

    @Output() readonly canEditVehicle: EventEmitter<boolean> = new EventEmitter();
    @Output() readonly removeVehicle: EventEmitter<UntypedFormControl> = new EventEmitter();
    @Output() readonly validateEditionVehicle: EventEmitter<Vehicle> = new EventEmitter();

    isEdit: boolean = false;
    destroy$: Subject<void> = new Subject();
    showOldModelValue: boolean = true;
    showOldVersionValue: boolean = true;
    VehicleUsageType = VehicleUsageType;
    VehicleAcquisitionType = VehicleAcquisitionType;
    currentVehicleIdentifierNumber: string;
    isRigeUser: boolean;

    form: UntypedFormGroup;
    vehicleVersionForm: UntypedFormGroup;
    makeCtrl: UntypedFormControl;
    licenseNumberCtrl: UntypedFormControl;
    vehicleIdentifierNumberCtrl: UntypedFormControl;
    carRegistrationDocumentCtrl: UntypedFormControl;

    mediasCanActivate: MediaPublicCodeDto[] = [];
    mediaCanActivateExist: boolean | undefined = undefined;
    private mediaCanActivateSubject: Subject<string> = new Subject();
    private mediaCanActivateSubscription: Subscription;

    constructor(private readonly fb: UntypedFormBuilder,
        private readonly personHttpService: FrontPersonHttpService,
        private readonly notificationService: NotificationService,
        private readonly vehicleVersionHttpService: FrontVehicleVersionHttpService,
        private readonly uploadHttpService: FrontUploadHttpService,
        private readonly personPortalContextService: FrontPersonPortalContextService,
        private readonly mediaService: FrontMediaHttpService,
        private readonly router: Router,
        @Inject(ACCEPTED_FILE_FORMATS) public readonly fileFormats: string[]) {
    }

    ngOnInit(): void {
        this.personPortalContextService.isRigeUser().subscribe(isRigeUser => this.isRigeUser = isRigeUser);

        this.initFormData();

        this.mediaCanActivateSubscription = this.mediaCanActivateSubject.pipe(
            debounceTime(200),
            switchMap((mediaCode) => this.mediaService.findLightMedias(
                {
                    foAccountId: this.foAccountRef,
                    partialPublicCode: mediaCode,
                    withDeactivated: false,
                    withoutLinkedToVehicle: true,
                    limit: 20
                }
            ))
        ).subscribe(mediaPublicCodeDtos => {
            this.mediasCanActivate = this.vehicle.value.mediaUsages.length ? [...this.vehicle.value.mediaUsages, ...mediaPublicCodeDtos] : mediaPublicCodeDtos;
            if (!this.mediaCanActivateExist) {
                this.mediaCanActivateExist = this.mediasCanActivate.length > 0;
            }
        });
    }

    private initFormData() {
        this.makeCtrl = this.fb.control(this.vehicle.value.vehicleVersion.make, Validators.required);

        this.vehicleVersionForm = this.fb.group({
            make: this.makeCtrl,
            model: '',
            version: ''
        });

        this.licenseNumberCtrl = this.fb.control(this.vehicle.value.licenseNumber);
        this.vehicleIdentifierNumberCtrl = this.fb.control(this.vehicle.value.vehicleIdentifierNumber);
        this.carRegistrationDocumentCtrl = this.fb.control(null, carRegistrationFileExtensionValidator(this.fileFormats));
        this.form = this.fb.group({
            vehicleVersion: this.vehicleVersionForm,
            licenseNumber: this.licenseNumberCtrl,
            vehicleIdentifierNumber: this.vehicleIdentifierNumberCtrl,
            vehicleUsageType: this.vehicle.value.vehicleUsageType,
            vehicleAcquisitionType: this.vehicle.value.vehicleAcquisitionType,
            leasingPartner: this.vehicle.value.leasingPartner,
            detail: this.vehicle.value.detail,
            media: [''],
            priority: this.vehicle.value.priority,
            carRegistrationFile: this.carRegistrationDocumentCtrl
        });

        this.form.get('vehicleVersion.model').disable({emitEvent: false});
        this.form.get('vehicleVersion.version').disable({emitEvent: false});
        this.form.get('priority').disable({emitEvent: false});

        this.makeCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.onMakeChange());
        this.form.get('vehicleVersion.model').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.onModelChange());
    }

    private onMakeChange() {
        if (!this.isEdit) {
            return;
        }

        this.showOldModelValue = false;
        const make: string = this.form.get('vehicleVersion.make').value;

        if (make) {
            this.vehicleVersionHttpService.findModelsByMake(make)
                .pipe(shareReplay(1))
                .subscribe(models => {
                    this.vehicleModels = models;
                    const model: AbstractControl = this.form.get('vehicleVersion.model');

                    if (models.length && model.disabled) {
                        model.enable();
                        model.setValidators(Validators.required);
                    } else if (!models.length) {
                        model.disable();
                        model.clearValidators();
                    }
                    model.reset();
                    model.setValue('', {emitEvent: false});

                });
        }
    }

    private onModelChange() {
        if (!this.isEdit) {
            return;
        }

        this.showOldVersionValue = false;
        const make: string = this.form.get('vehicleVersion.make').value;
        const model: string = this.form.get('vehicleVersion.model').value;

        if (make && model) {
            this.vehicleVersionHttpService
                .findVersionsByMakeModel(make, model)
                .pipe(shareReplay(1))
                .subscribe((versions => {
                    this.vehicleVersions = versions;
                    const version: AbstractControl = this.form.get('vehicleVersion.version');

                    if (versions.length && version.disabled) {
                        version.enable();
                    } else if (!versions.length) {
                        version.disable();
                        version.clearValidators();
                    }

                    version.reset();

                    /**
                     * Setting version to initial is a workaround used to be able to select standard vehicle version
                     * without breaking anything in the mobile app.
                     * An issue was open to do that in a better way: https://issues.4sh.fr/browse/SMBP-10790
                     */
                    version.setValue('initial', {emitEvent: false});
                }));
        }
    }

    public deleteVehicle(vehicle: UntypedFormControl) {
        this.removeVehicle.emit(vehicle);
    }

    public editVehicle() {
        this.licenseNumberCtrl.setValidators([Validators.required, vehicleUpdateUniquenessValidator(this.licenseNumbers)]);
        this.vehicleIdentifierNumberCtrl.setValidators([Validators.maxLength(17), vehicleUpdateUniquenessValidator(this.vehicleIdentifierNumbers)]);

        this.isEdit = !this.isEdit;
        this.form.get('media').setValue(this.vehicle.value.mediaUsages[0]);
        this.mediaCanActivateExist = false;
        this.mediaCanActivateSubject.next(null);
        this.canEditVehicle.emit(false);
        this.form.get('priority').enable();
        this.licenseNumbers.delete(this.form.value.licenseNumber.toLowerCase());
        this.vehicleIdentifierNumbers.delete(this.form.value.vehicleIdentifierNumber.toLowerCase());
        this.currentVehicleIdentifierNumber = this.form.value.vehicleIdentifierNumber.toLowerCase();
        this.collapsibleCard.isCollapsed = false;
    }

    public cancelEditVehicle() {
        this.isEdit = false;
        this.canEditVehicle.emit(true);
        this.setFormInitialValues();
        this.licenseNumbers.add(this.form.value.licenseNumber.toLowerCase());
        this.vehicleIdentifierNumbers.add(this.form.value.vehicleIdentifierNumber.toLowerCase());
        this.collapsibleCard.isCollapsed = true;
    }

    private setFormInitialValues() {
        this.form.get('priority').setValue(this.vehicle.value.priority);
        this.form.get('priority').disable({emitEvent: false});

        this.form.get('licenseNumber').setValue(this.vehicle.value.licenseNumber);
        this.form.get('vehicleIdentifierNumber').setValue(this.vehicle.value.vehicleIdentifierNumber);
        this.form.get('detail').setValue(this.vehicle.value.detail);
        this.form.get('media').setValue('');
        this.form.get('vehicleVersion.make').setValue(this.vehicle.value.vehicleVersion.make);

        this.showOldModelValue = true;
        this.form.get('vehicleVersion.model').setValue('');
        this.form.get('vehicleVersion.model').disable({emitEvent: false});
        this.form.get('vehicleVersion.model').markAsUntouched();
        this.form.get('vehicleVersion.model').markAsPristine();

        this.showOldVersionValue = true;
        this.form.get('vehicleVersion.version').setValue('');
        this.form.get('vehicleVersion.version').disable({emitEvent: false});
    }

    public updateVehicle() {
        this.isEdit = !this.isEdit;
        this.canEditVehicle.emit(true);

        const vehicleToUpdate: Vehicle = {
            id: this.vehicle.value.id,
            vehicleVersion: {
                make: this.form.value.vehicleVersion.make,
                model: this.form.value.vehicleVersion.model != null ? this.form.value.vehicleVersion.model : this.vehicle.value.vehicleVersion.model,
                version: this.form.value.vehicleVersion.version != null ? this.form.value.vehicleVersion.version : this.vehicle.value.vehicleVersion.version,
            },
            priority: this.form.value.priority,
            licenseNumber: this.form.value.licenseNumber,
            vehicleIdentifierNumber: this.form.value.vehicleIdentifierNumber !== '' ?
                this.form.value.vehicleIdentifierNumber : this.currentVehicleIdentifierNumber,
            detail: this.form.value.detail,
            vehicleUsageType: this.form.value.vehicleUsageType,
            vehicleAcquisitionType: this.form.value.vehicleAcquisitionType,
            leasingPartner: this.form.value.leasingPartner,
            mediaId: this.form.value.media?.mediaId,
            carRegistrationFile: this.vehicle.value.carRegistrationFile,
            timezone: this.vehicle.value.timezone,
            externalVehicleRefByServiceProvider: this.vehicle.value.externalVehicleRefByServiceProvider,
            externalMediaRefByServiceProvider: this.vehicle.value.externalMediaRefByServiceProvider,
            mediaUsages: this.form.value.media ? [this.form.value.media] : []
        };

        const files: FileList = this.form.value.carRegistrationFile;
        if (files && files.length) {
            this.uploadHttpService.upload(files[0], files[0].name)
                .subscribe(
                    fileReference => {
                        vehicleToUpdate.carRegistrationFile = {id: fileReference.fileId,
                            name: files[0].name
                        };
                        this.validateEditionVehicle.emit(vehicleToUpdate);
                    },
                    () => this.notificationService.error('upload.error')
                );
        } else {
            this.validateEditionVehicle.emit(vehicleToUpdate);
        }

        this.form.get('vehicleVersion.model').disable({emitEvent: false});
        this.form.get('vehicleVersion.model').markAsUntouched();
        this.form.get('vehicleVersion.model').markAsPristine();
        this.form.get('vehicleVersion.version').disable({emitEvent: false});
        this.form.get('priority').disable();

        this.collapsibleCard.isCollapsed = true;
    }

    public trackByMediaId(index: number, media: MediaPublicCodeDto) {
        return media?.mediaId ?? index;
    }

    public trackByName(index: number, vehicle: VehicleMakeDto | VehicleModelDto) {
        return vehicle?.name ?? index;
    }

    public trackByVehicleVersionId(index: number, vehicleVersion: VehicleVersionDto) {
        return vehicleVersion?.id ?? index;
    }

    public editPlanning() {
        this.router.navigate([`${this.baseUrl}/planning/${this.vehicle.value.id}`], {
            queryParams: { licenseNumber: this.vehicle.value.licenseNumber, timezone: this.vehicle.value.timezone }
        });
    }

    public isVehicleCreatedInDreevProvider(): boolean {
        return FleetVehicleUtils.isVehicleCreatedInDreevProvider(this.vehicle.value);
    }

    public isVehicleMediaAssociatedInDreevProvider(vehicle: UntypedFormControl, mediaId: string): boolean {
        return FleetVehicleUtils.isVehicleMediaAssociatedInDreevProvider(vehicle, mediaId);
    }

    public sortNull() {
        return FleetVehicleUtils.sortNull();
    }

    public searchMedias(mediaCode: string) {
        this.mediaCanActivateSubject.next(mediaCode);
    }

    ngOnDestroy(): void {
        this.mediaCanActivateSubscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
