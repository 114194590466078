<form class="has-space" [formGroup]="form">
    <fieldset>
        <legend>{{'media.importExport' | translate}}</legend>
        <import-export
            [exportUrl]="'/api/foAccounts/' + foAccountRef + '/collaborators/export/xlsx' + getQueryParamsFromCriteria()"
            [uploadText]="'import.collaborator.button' | translate"
            [exportType]="'collaborator'"
            [createImportRequestReportObs]="createCollaboratorImportRequestReportObs"
            [fetchImportRequestReportObs]="fetchImportRequestReport"
            [importRequestObs]="importRequestObs"
            [hasWriteRole]="hasCollaboratorsWriteRole"
            (importRequestProcessingEnded)="searchCollaborators()">
        </import-export>
    </fieldset>
    <fieldset>
        <legend>{{'media.filter.title' | translate}}</legend>
        <div class="grid-x align-middle grid-padding-x grid-margin-y">
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.firstName && form.controls.firstName?.dirty && form.controls.firstName.value?.length > 0}">
                <input class="search" type="text"
                       placeholder="{{'config.collaborators.placeholder.firstName' | translate}}"
                       name="firstName" [formControlName]="'firstName'"
                       (keyup.enter)="searchCollaborators()">
                <i class="fas fa-search search-icon"></i>
            </div>
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.lastName && form.controls.lastName?.dirty && form.controls.lastName.value?.length > 0}">
                <input class="search" type="text"
                       placeholder="{{'config.collaborators.placeholder.lastName' | translate}}"
                       name="lastName" [formControlName]="'lastName'"
                       (keyup.enter)="searchCollaborators()">
                <i class="fas fa-search search-icon"></i>
            </div>
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.email && form.controls.email?.dirty && form.controls.email.value?.length > 0}">
                <input class="search" type="text"
                       placeholder="{{'config.collaborators.placeholder.email' | translate}}"
                       name="email" [formControlName]="'email'"
                       (keyup.enter)="searchCollaborators()">
                <i class="fas fa-search search-icon"></i>
            </div>
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.withPass && form.controls.withPass?.dirty && form.controls.withPass.value?.length > 0}">
                <select [formControlName]="'withPass'"
                        name="withPass">
                    <option [value]="''">{{'config.collaborators.placeholder.pass' | translate}}</option>
                    <option [value]="true">{{"config.collaborators.pass.withPass" | translate}}</option>
                    <option [value]="false">{{"config.collaborators.pass.withoutPass" | translate}}</option>
                </select>
            </div>
            <div *ngIf="!fleetConfig?.managerCreationOnly"
                 class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.role && form.controls.role?.dirty && form.controls.role.value?.length > 0}">
                <select [formControlName]="'role'"
                        name="role">
                    <option [value]="''">{{'config.collaborators.placeholder.responsibility' | translate}}</option>
                    <option
                        [value]="Responsibility.COLLABORATOR">{{"config.collaborators.responsibility.collaborator" | translate}}</option>
                    <option
                        [value]="Responsibility.MANAGER">{{"config.collaborators.responsibility.manager" | translate}}</option>
                </select>
            </div>
            <div class="cell small-8 xxlarge-shrink">
                <button class="button primary _fullWidth" type="button" (click)="searchCollaborators()">
                    {{'collaborator.search.title' | translate}}
                </button>
            </div>
        </div>
        <div class="grid-x align-middle align-justify grid-padding-x grid-padding-y force-space-top-12">
            <div class="cell small-18 xxxlarge-shrink">
                <div class="info common-block force-space-bottom-0"
                     *ngIf="nbrTotalCollaborators > searchLimit">
                    {{'config.collaborator.search.limit' | translate}}
                </div>
            </div>
            <indicator class="cell small-5 force-space-right-0" [img]="'users'">
                <ng-container value>{{nbrTotalCollaborators | number}}</ng-container>
            </indicator>
        </div>
    </fieldset>

    <div class="space-top-25">
        <next-last-page *ngIf="!searching" [pageNumber]="pageNumber" [maxPage]="nbrPageMax"
                        (changePage)="pageChange.emit($event)"></next-last-page>
    </div>

    <ng-container [formArrayName]="collaboratorsFormName" *ngIf="fleetConfig">
        <div class="grid-x grid-margin-x">
            <div class="cell account-list-header"
                 [ngClass]="{'medium-20': hasCollaboratorsWriteRole}">
                <div class="grid-x grid-padding-x align-middle account-list-header-content">
                    <div [ngClass]="{'small-4': multiAdminEnabled() || isRefundManager, 'small-6': !multiAdminEnabled() && !isRefundManager,
                                     'small-5': !multiAdminEnabled() || !isRefundManager}"
                         class="cell account-list-header-item">{{'config.collaborator.name.title' | translate}}</div>

                    <div [ngClass]="{'small-4': multiAdminEnabled() || isRefundManager, 'small-6': !multiAdminEnabled() && !isRefundManager,
                                     'small-5': !multiAdminEnabled() || !isRefundManager}"
                         class="cell account-list-header-item">{{'config.collaborator.lastname.title' | translate}}</div>

                    <div [ngClass]="{'small-4': multiAdminEnabled() || isRefundManager, 'small-6': !multiAdminEnabled() && !isRefundManager,
                                     'small-5': !multiAdminEnabled() || !isRefundManager}"
                         class="cell  account-list-header-item">{{'config.collaborator.email.title' | translate}}</div>

                    <div [ngClass]="{'small-4': multiAdminEnabled() || isRefundManager, 'small-6': !multiAdminEnabled() && !isRefundManager,
                                     'small-5': !multiAdminEnabled() || !isRefundManager}"
                         class="cell account-list-header-item">{{'config.collaborator.media.title' | translate}}</div>

                    <div *ngIf="isRefundManager"
                         class="cell small-4 account-list-header-item">
                        {{'config.collaborator.expenseReportStation.title' | translate}}
                        <span data-tooltip
                              class="top"
                              title="{{'config.collaborator.expenseReportStation.tooltip' |
                                translate: {supervisedStationCount: supervisedStationCount} }}">
                            <i class="fa fa-info-circle">
                            </i>
                        </span>
                        <div>{{collaboratorWithSupervisedStationCount}} / {{supervisedStationCount}}</div>
                    </div>

                    <div *ngIf="multiAdminEnabled"
                         class="cell small-4 account-list-header-item">{{'config.collaborator.responsibility.title' | translate}}</div>
                </div>
            </div>

            <button type="button" class="addButton cell medium-4 button secondary color add"
                    *ngIf="hasCollaboratorsWriteRole"
                    (click)="addItemForm()"
                    [disabled]="!canAddCollaborator">
                {{'config.collaborators.add' | translate}}
            </button>
        </div>

        <div class="grid-x" *ngIf="!searching else spinner">
            <div class="cell align-middle space-top-25"
                 *ngFor="let collaboratorEntry of collaboratorsForm.controls;let first = first;let last = last; let index = index">
                <div class="grid-x grid-margin-x">
                    <collaborator-form class="cell small-auto medium-auto large-auto"
                                       [formControlName]="index"
                                       [responsibilities]="responsibilities"
                                       [hasCollaboratorsWriteRole]="hasCollaboratorsWriteRole"
                                       [showResponsibility]="multiAdminEnabled()"
                                       [isRefundManager]="isRefundManager"
                                       [collaboratorWithSupervisedStationCount]="collaboratorWithSupervisedStationCount"
                                       [supervisedStationCount]="supervisedStationCount"
                                       [mediasObs]="mediasObs"
                                       [medias]="collaboratorEntry?.value?.medias"
                                       (withSupervisedStationChanged)="withSupervisedStationChanged($event)"></collaborator-form>

                    <div class="cell small-4 text-right"
                         *ngIf="hasCollaboratorsWriteRole">
                        <button type="button" class="button round primary"
                                (click)="addUserToFleetRequest(index)"
                                [iziviaLoading]="addingUser"
                                [disabled]="!collaboratorEntry?.valid"
                                *ngIf="collaboratorEntry?.value?.mode === 'CREATE'">
                            <i class="fas fa-check"></i>
                        </button>

                        <button type="button" class="button round secondary titleDisabledButton"
                                [title]="('collaborator.view.permission'| translate)"
                                (click)="redirectAndFilterOnPermissionTab(collaboratorEntry)"
                                *ngIf="collaboratorEntry?.value?.mode !== 'CREATE' && hasSubscriberRolesReadRole && collaboratorEntry?.value?.responsibility === Responsibility.MANAGER">
                            <i class="fas fa-eye"></i>
                        </button>

                        <button type="button" class="button round secondary titleDisabledButton"
                                [title]="(collaboratorEntry?.value?.responsibility ===  Responsibility.MANAGER ? 'collaborator.deleteManager.title' : 'collaborator.delete.title') | translate"
                                [ngClass]="{'button-alignment' : !last}"
                                (click)="deleteCollaborator(index, collaboratorEntry?.value?.responsibility)"
                                [iziviaLoading]="deletingUser"
                                *ngIf="collaboratorEntry?.value?.mode !== 'CREATE'">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>


                    <span class="cell error note" [hidden]="!(form.invalid && form.errors?.mailCollaborators)">
                        {{'config.collaborators.mail.error' | translate}}
                    </span>
                </div>
            </div>
        </div>
    </ng-container>

    <next-last-page *ngIf="!searching" [pageNumber]="pageNumber" [maxPage]="nbrPageMax"
                    (changePage)="pageChange.emit($event)"></next-last-page>
</form>

<ng-template #spinner>
    <div class="app-loader small text-center">
        <span class="spinner small"></span>
    </div>
</ng-template>
