import {AbstractControl, UntypedFormArray, ValidationErrors, ValidatorFn} from '@angular/forms';

export function vehicleCreationUniquenessValidator(vehicleProperty: string, vehiclesForm: UntypedFormArray): ValidatorFn {
    return (vehicleControl: AbstractControl): ValidationErrors | null => {
        const newValue = vehicleControl.value;
        if (newValue == null) {
            return null;
        }

        const existingValues = vehiclesForm.value.map(vehicle => vehicle[vehicleProperty].toLowerCase());
        return existingValues.includes(newValue.toLowerCase()) ? { notUnique: true } : null;
    };
}
