import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    buildRole,
    ConnectedUser,
    CurrentSubscriberRolesService,
    emptyUser,
    FOAccountWrapper,
    FrontAccountHttpService,
    FrontFoAccountHttpService,
    FrontSessionBusinessService,
    Language,
    SubscriberRoleLevel,
    SubscriberRoleType,
    User
} from 'lib-front';
import {Router} from '@angular/router';
import {ProgressingImportService} from '../services/events/progressingImport.service';
import {Observable, Subscription} from 'rxjs';
import {AVAILABLE_APP_LANGUAGES, ServerLanguage} from '../services/i18n/i18n.config';
import {appLanguageToServerLanguage} from '../services/i18n/i18n.function';
import {TranslateService} from '@ngx-translate/core';
import {find, isEmpty} from 'lodash-es';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {CurrentUserContextService} from '../services/business/currentUserContext.service';
import {FrontEndService} from '../services/frontEnd.service';

@Component({
    selector: 'app-root-header',
    templateUrl: './appRootHeader.component.html',
    styleUrls: ['./appRootHeader.component.scss']
})
export class AppRootHeaderComponent implements OnInit, OnDestroy {
    languages: Language[] = AVAILABLE_APP_LANGUAGES;

    public foAccounts: FOAccountWrapper[] = [];
    public currentFoAccount: FOAccountWrapper;
    public isFoAccountPanelOpen: boolean = false;
    public hasConfigurationReadRole: boolean = false;
    public finalClientConfigurable: boolean = false;
    public allowToSignUp: boolean = true;
    private principal: ConnectedUser;
    private currentUserSubscription: Subscription;
    private foAccountHeaderId: string;
    private foAccountIdSubscription: Subscription;

    public loggingOut: boolean;

    constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly frontEndService: FrontEndService,
        private readonly frontAccountHttpService: FrontAccountHttpService,
        private readonly progressingImportService: ProgressingImportService,
        public readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly foAccountService: FrontFoAccountHttpService,
        private readonly currentUserContextService: CurrentUserContextService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
    }

    ngOnInit(): void {
        this.currentUserSubscription = this.sessionBusinessService.connectedUser$
            .subscribe(connectedUser => {
                this.principal = connectedUser;
                if (connectedUser && isEmpty(this.foAccounts)) {
                    this.foAccountService.findFOAccounts()
                        .pipe(
                            tap((foAccounts) => this.foAccounts = foAccounts),
                            switchMap(() => this.foAccountService.getCurrentFoAccountId())
                        )
                        .subscribe((foAccountId) => {
                            this.currentFoAccount = find(this.foAccounts, ((foAccount) => foAccount.foAccountRef === foAccountId));
                        });
                }
            });

        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.CONFIGURATION_TAB, SubscriberRoleLevel.READ)
        ).subscribe(hasRole => this.hasConfigurationReadRole = hasRole);

        this.frontEndService.currentFrontEndInfo$
            .subscribe(frontEndInfo => {
                this.finalClientConfigurable = frontEndInfo.fleetConfig?.finalClientConfigurable ?? false;
                this.allowToSignUp = frontEndInfo.fleetConfig?.allowToSignUp ?? true;
            });
    }

    ngOnDestroy(): void {
        this.currentUserSubscription?.unsubscribe();
        this.foAccountIdSubscription?.unsubscribe();
    }

    public get user(): User {
        return this.principal ? this.principal.user : emptyUser();
    }

    public get currentImportIds$(): Observable<string[]> {
        return this.progressingImportService.currentImportIds$;
    }

    public logout(): void {
        this.loggingOut = true;
        this.foAccounts = [];
        this.currentFoAccount = null;

        this.sessionBusinessService.logout()
            .pipe(
                finalize(() => this.loggingOut = false)
            )
            .subscribe(() => {
                this.currentUserContextService.reset();
                this.router.navigate(['/login']).then(() => {
                    // Force a refresh to clean cached data (e.g. in services)
                    window.location.reload();
                });
            });
    }

    public isConnected(): boolean {
        return !!this.principal;
    }

    public isOnRegister(): boolean {
        return this.router.routerState.snapshot.url.indexOf('register') !== -1;
    }

    public changeLanguage(lang: Language): void {
        const serverLanguage = appLanguageToServerLanguage(lang);
        if (this.isConnected()) {
            this.frontAccountHttpService.updateAccountLocale(this.principal.user, serverLanguage)
                .subscribe(() => {
                    this.updateLanguageAndReload(serverLanguage);
                });
        } else {
            this.updateLanguageAndReload(serverLanguage);
        }
    }

    private updateLanguageAndReload(serverLanguage: ServerLanguage): void {
        this.sessionBusinessService.setLanguage(serverLanguage);
        window.location.reload();
    }

    openCloseFoAccountPanel() {
        this.isFoAccountPanelOpen = !this.isFoAccountPanelOpen;
    }

    changeCurrentFoAccount(foAccount: FOAccountWrapper) {
        this.foAccountService.changeCurrentFoAccount(foAccount.foAccountRef).subscribe(() => {
            window.location.reload();
        });
    }
}

