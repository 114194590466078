<fleet-list-form [(ngModel)]="vehicles" name="vehicles"
                 [nbrVehicles]="nbrVehicles"
                 [searching]="searching"
                 [hasFleetWriteRole]="hasFleetWriteRole"
                 [pageNumber]="pageNumber"
                 [nbrPageMax]="nbrPageMax"
                 [displayLimitMessage]="displayLimitMessage"
                 (searchChange)="searchByText($event)"
                 (nbrVehiclesChange)="updateNbrVehicles($event)"
                 (pageChange)="search(criteria, $event)">
</fleet-list-form>
