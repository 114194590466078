import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
    ConsumptionPrice,
    FrontPackSubscriptionHttpService,
    FrontPaymentHttpService,
    MediaOrder,
    PaymentType,
    isRecurringPaymentType,
    SubscriptionMode,
    SubscriptionPack,
    SubscriptionPriceRequestDto
} from 'lib-front';
import {SubscriptionFormValue} from '../subscription/register-subscription.component';
import {MediaOrderDto} from '../../../domain/mediaOrderDto';
import {AccountFormValue} from '../../../components/informations/account-informations.component';
import {PromoTokenWithSubscriptionPack} from '../../../domain/promoTokenWithSubscriptionPack';

@Component({
    selector: 'register-payment',
    templateUrl: './register-payment.component.html',
    styleUrls: ['./register-payment.component.scss'],
    host : {'class': 'cell grid-y'}
})
export class RegisterPaymentComponent {
    SubscriptionMode = SubscriptionMode;
    @Input() account: AccountFormValue;
    @Input() subscription: SubscriptionFormValue;
    @Input() set mediaOrder(value: MediaOrderDto) {
        this._mediaOrder = value;
        this.computeTotalPrice();
    }
    @Input() disabled = false;
    @Input() set emoRef(emoRef: string) {
        if (emoRef) {
            this.paymentHttpService.findPaymentType(emoRef).subscribe(datas => this.paymentTypesAvailables = datas);
        }
    }

    @Output() back: EventEmitter<void> = new EventEmitter<void>();
    @Output() paymentEvent: EventEmitter<PaymentType> = new EventEmitter<PaymentType>();
    @Output() paymentConfigEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() promoTokenChange: EventEmitter<string> = new EventEmitter<string>();

    get mediaOrder() {
        return this._mediaOrder;
    }

    get bonusPenaltyWithVats() {
        return this._bonusPenaltyWithVats;
    }
    set bonusPenaltyWithVats(value: ConsumptionPrice[]) {
        this._bonusPenaltyWithVats = value;
        this.computeTotalPrice();
    }

    paymentTypesAvailables: PaymentType[];
    paymentSelected: PaymentType;
    paymentConfigName: string;
    promoToken: PromoTokenWithSubscriptionPack;
    totalMediaPrice: number;
    private _bonusPenaltyWithVats: ConsumptionPrice[] = [];
    private _mediaOrder: MediaOrderDto;

    constructor(private paymentHttpService: FrontPaymentHttpService,
        private packSubscriptionService: FrontPackSubscriptionHttpService) {
    }

    public selectPayment(paymentType: PaymentType) {
        this.paymentSelected = paymentType;
    }

    public setPaymentConfigName(configName: string) {
        this.paymentConfigName = configName;
    }

    public configNameIsValid() {
        return !this.paymentSelected
          || !isRecurringPaymentType(this.paymentSelected)
          || this.paymentConfigName;
    }

    public stepBack() {
        this.back.emit();
    }

    public showSubscriptionPacksPrice(): boolean {
        if (!this.subscription
          || !this.subscription.subscriptionPackOrTokenWrapper.subscriptionPacks
          || !this.subscription.subscriptionPackOrTokenWrapper.subscriptionPacks.length) {
            return true;
        }
        return this.subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks.length > 1
          || this.subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks[0].overloadedFixPriceLabel === null;
    }

    public subscriptionPacksPrice(): number {
        if (!this.subscription
          || !this.subscription.subscriptionPackOrTokenWrapper.subscriptionPacks
          || !this.subscription.subscriptionPackOrTokenWrapper.subscriptionPacks.length) {
            return 0;
        }
        return this.subscription.subscriptionPackOrTokenWrapper.subscriptionPacks
            .reduce((price, subscriptionPack) => price + subscriptionPack.fixPriceWithVat, 0);
    }

    public get totalOptions(): number {
        if (!this.subscription || !this.subscription.options || !this.subscription.options.length) {
            return 0;
        }

        return this.subscription.options
            .map(optionChoose => (optionChoose.number || 0) * optionChoose.subscription.fixAmountWithVAT )
            .reduce((previousValue, currentValue) => previousValue + currentValue);
    }

    onPayment () {
        if (this.paymentTypesAvailables) {
            this.paymentConfigEvent.emit(this.paymentConfigName);
            this.paymentEvent.emit(this.paymentSelected);
        }
    }

    onInscription() {
        this.paymentEvent.emit();
    }

    trackBySubscriptionPackId(index: number, subscriptionPack: SubscriptionPack) {
        return subscriptionPack?._id ?? index;
    }

    calculatePrice() {
        const subscriptionPriceRequestDto: SubscriptionPriceRequestDto = {
            promoSubscription: this.promoToken.token,
            subscriptionMode: SubscriptionMode.register,
            mediaOrders: [],
            subscriptionPackRef: null,
            packToken: null
        };

        const subscriptionPackOrTokenWrapper = this.subscription.subscriptionPackOrTokenWrapper;

        if (this._mediaOrder?.quantity) {
            const mediaOrder: MediaOrder = {
                quantity: this._mediaOrder.quantity,
                mediaFamilyRef: this._mediaOrder.mediaCanOrderDto.mediaFamily.id,
                subscriptionPackRef: subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe._id
            };
            subscriptionPriceRequestDto.mediaOrders.push(mediaOrder);
        }

        if (subscriptionPackOrTokenWrapper.isSubscriptionPackMode()) {
            subscriptionPriceRequestDto.subscriptionPackRef =
              subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe._id;
        } else {
            subscriptionPriceRequestDto.packToken = subscriptionPackOrTokenWrapper.tokenValue;
        }

        this.packSubscriptionService.computePrices(subscriptionPriceRequestDto).subscribe(subscriptionPrices => {
            this.bonusPenaltyWithVats = subscriptionPrices.bonusPenaltyWithVats;
        });
    }

    promoTokenChanged(promoToken: PromoTokenWithSubscriptionPack) {
        this.promoToken = promoToken;

        if (this.promoToken.valid) {
            this.promoTokenChange.emit(promoToken.token);
            this.calculatePrice();
        } else {
            this.promoTokenChange.emit(null);
            this.bonusPenaltyWithVats = [];
        }
    }

    private computeTotalPrice() {
        const mediaBestPrice: number = this._mediaOrder?.emoMediaOrderPrice?.bestPrice || 0;
        const bonus: number = this._bonusPenaltyWithVats
            .reduce((previousValue, currentValue) => previousValue + currentValue.priceWithVat, 0);
        this.totalMediaPrice = mediaBestPrice + bonus;
    }
}
