import {Vehicle} from 'lib-front';
import {UntypedFormControl} from '@angular/forms';

export class FleetVehicleUtils {

    private static readonly DREEV_SERVICE_PROVIDER = 'DREEV';

    public static isVehicleCreatedInDreevProvider(vehicle: Vehicle): boolean {
        if (vehicle.externalVehicleRefByServiceProvider) {
            return vehicle.externalVehicleRefByServiceProvider[this.DREEV_SERVICE_PROVIDER] != null;
        }
        return false;
    }

    public static isVehicleMediaAssociatedInDreevProvider(vehicle: UntypedFormControl, mediaId: string): boolean {
        if (vehicle.value.externalMediaRefByServiceProvider) {
            return vehicle.value.externalMediaRefByServiceProvider[this.DREEV_SERVICE_PROVIDER] === mediaId;
        }
        return false;
    }

    //  Disable the default keyvalue pipe sort
    public static sortNull() {
        return 0;
    }
}
