import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    FILE_API_PATH,
    FleetUser,
    FOAccountWrapper,
    FrontEndHttpService,
    FrontFoAccountHttpService,
    FrontInvoiceHttpService,
    FrontPersonHttpService,
    PersonAddress,
    newPersonAddress,
    FrontUploadHttpService,
    IziviaPasswordValidatorBuilder,
    IziviaPasswordValidatorDefinition,
    PASSWORD_VALIDATORS,
    RGPDWorkflowHttpService,
    HOST_THIRD_PARTY_ID,
    FrontSessionBusinessService,
    FrontUserAccountHttpService,
    canBeClosed,
    UserClosableAccountStateDto, Location
} from 'lib-front';
import {Md5} from 'ts-md5';
import {NgModel, Validator} from '@angular/forms';
import {filter, finalize, switchMap, tap} from 'rxjs/operators';
import {NotificationService} from '../../services/utils/notification.service';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {IziviaPopupComponent} from '../izivia-popup/izivia-popup.component';
import { ModeAddressForm } from '../address-form/modeAddressForm';
import {FrontEndService} from '../../services/frontEnd.service';

@Component({
    selector: 'account-informations',
    templateUrl: './account-informations.component.html',
    styleUrls: ['./account-informations.component.scss']

})
export class AccountInformationsComponent implements OnInit {

    @Input()
    public buttonText: string;
    @Input()
    public user: FleetUser;
    @Input()
    public isRegister: boolean = false;
    @Input()
    public saving: boolean;
    @Output()
    private userValidated = new EventEmitter<AccountFormValue>();

    public ModeAddressForm = ModeAddressForm;
    public closableAccountState: UserClosableAccountStateDto;

    @ViewChild('closingAccountStatePopup')
    public closingAccountStatePopup: IziviaPopupComponent;

    @ViewChild('pwd', {static: false}) set pwd(pwd: NgModel) {
        if (pwd) {
            this._pwd = pwd;
            this._pwd.control.addValidators(this.passwordValidators.map(validator => validator.validate.bind(validator)));
        }
    }

    public billingAutoComplete: boolean;
    public deliveryAutoCompleteAdministrator: boolean;
    public deliveryAutoCompleteBilling: boolean;
    public isPasswordChange: boolean = false;
    public password: string;


    @Input()
    public login: string = '';
    public foAccount: FOAccountWrapper;
    private _isValidUserAddress: boolean;
    set isValidUserAddress(value: boolean) {
        this._isValidUserAddress = value;
        if (this._isValidUserAddress) {
            this.completeAddress(this.user.address);
        }
    }
    get isValidUserAddress() {
        return this._isValidUserAddress;
    }

    public isValidBillingAddress: boolean;
    public isDirtyBillingAddress: boolean;
    public isValidDeliveryAddress: boolean;
    public isDirtyDeliveryAddress: boolean;
    public userAddressEnvelopeValidated: boolean;
    public hasUnpaidInvoice: boolean;
    public passwordValidators: Validator[];
    public _pwd: NgModel;

    public logo: FileList;

    private accountFormValue: AccountFormValue = new AccountFormValue();
    private emailSave;

    public rgpdFormUrl: string = null;
    public termsUrl: string = null;

    private _workflowId: string;
    public expenseReportRgpdDocumentUrl: string;
    public closingAccount: boolean;

    public siretMandatory: boolean;
    public declarableAsCollectivity: boolean;
    public finalClientConfigurable: boolean = false;
    public displayOptInAgreement: boolean = false;
    public displayAdvertAgreement: boolean = false;

    @Input()
    set workflowId(workflowId: string) {
        this._workflowId = workflowId;
        if(workflowId){
            this.rgpdWorkflowService.getSubscriberAcceptanceByWorkflowId(workflowId).subscribe(
                workflowAcceptance => this.expenseReportRgpdDocumentUrl = workflowAcceptance?.documentUrl
            );
        }
    }

    constructor(private readonly personHttpService: FrontPersonHttpService,
        private readonly foAccountService: FrontFoAccountHttpService,
        private readonly invoiceHttpService: FrontInvoiceHttpService,
        private readonly uploadHttpService: FrontUploadHttpService,
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string,
        private readonly frontEndHttpService: FrontEndHttpService,
        private readonly rgpdWorkflowService: RGPDWorkflowHttpService,
        private readonly notificationService: NotificationService,
        private readonly userAccountHttpService: FrontUserAccountHttpService,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly router: Router,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        @Inject(PASSWORD_VALIDATORS) public passwordValidatorDefinitions: IziviaPasswordValidatorDefinition[]) {

        this.passwordValidators = passwordValidatorDefinitions.map(def => IziviaPasswordValidatorBuilder.fromDefinition(def));
    }

    ngOnInit() {
        this.emailSave = this.user.email;
        this.userAddressEnvelopeValidated = !this.isRegister;
        this.loadLogin();

        this.frontEndHttpService.findFrontInfo(this.thirdPartyId).subscribe(result => {
            this.siretMandatory = result.fleetConfig?.siretMandatory ?? true;
            this.declarableAsCollectivity = result.fleetConfig?.declarableAsCollectivity ?? true;
            this.finalClientConfigurable = result.fleetConfig?.finalClientConfigurable ?? false;
            this.displayOptInAgreement = result.fleetConfig?.displayOptInAgreement ?? false;
            this.displayAdvertAgreement = result.fleetConfig?.displayAdvertAgreement ?? false;

            if(!this.isRegister){
                if (!!result.fleetConfig.rgpdTermsAndConditionsI18nCode) {
                    this.rgpdFormUrl = FILE_API_PATH + result.fleetConfig.rgpdTermsAndConditionsI18nCode;
                }
                if (!!result.fleetConfig.termsAndConditionsI18nCode) {
                    this.termsUrl = FILE_API_PATH + result.fleetConfig.termsAndConditionsI18nCode;
                }
            }
        });

    }

    public displayModalAccordingToClosableState(): void {
        this.closableAccountState = undefined;
        this.userAccountHttpService.currentAccountClosableState().subscribe(closableAccountState => {
            this.closableAccountState = closableAccountState;
            if(canBeClosed(closableAccountState)){
                this.closeAccount();
            } else {
                this.closingAccountStatePopup.open();
            }
        });
    }

    private closeAccount(): void {
        this.displayCloseAccountConfirmation().pipe(
            filter(confirm => confirm),
            tap(() => this.closingAccount = true),
            switchMap(() => this.userAccountHttpService.closeCurrentAccount()),
            switchMap(() => this.sessionBusinessService.logout()),
            finalize(() => this.closingAccount = false)
        ).subscribe(
            () => {
                this.notificationService.success('config.account.closeAccount.success');
                this.router.navigate(['/login']).then();
            },
            () => this.notificationService.error('config.account.closeAccount.error')
        );
    }

    private displayCloseAccountConfirmation(): Observable<boolean> {
        return this.alertService.confirm(
            this.translateService.instant('config.account.closeAccount.confirm.message'),
            null,
            this.translateService.instant('config.account.closeAccount.confirm.ok'),
            this.translateService.instant('config.account.closeAccount.confirm.cancel')
        );
    }

    public validate() {
        this.accountFormValue.user = this.user;
        this.accountFormValue.foAccount = this.foAccount;
        if (!!this.password) {
            this.accountFormValue.password = Md5.hashStr(this.password);
        }
        this.userValidated.emit(this.accountFormValue);
    }

    public complete(event: string, field: string, isAdministrator: boolean = true): void {
        if (isAdministrator) {
            this.user[field] = event;
        } else {
            this.foAccount.billing[field] = event;
        }
        if (isAdministrator && this.billingAutoComplete) {
            this.foAccount.billing[field] = this.user[field];
        }
        if (isAdministrator && this.deliveryAutoCompleteAdministrator) {
            this.foAccount.delivery[field] = this.user[field];
        }
        if (!isAdministrator && this.deliveryAutoCompleteBilling) {
            this.foAccount.delivery[field] = this.foAccount.billing[field];
        }
    }

    public completeAddress(address: Location, isAdministrator: boolean = true): void {
        if (isAdministrator) {
            this.user.address = address;
        } else {
            this.foAccount.billing.address = address;
        }
        if (isAdministrator && this.billingAutoComplete) {
            this.foAccount.billing.address = this.user.address;
        }
        if (isAdministrator && this.deliveryAutoCompleteAdministrator) {
            this.foAccount.delivery.address = this.user.address;
        }
        if (!isAdministrator && this.deliveryAutoCompleteBilling) {
            this.foAccount.delivery.address = this.foAccount.billing.address;
        }
    }

    private completeFromUser(address: PersonAddress): void {
        address.firstName = this.user.firstName;
        address.lastName = this.user.lastName;
        address.address.route = this.user.address.route;
        address.address.postalCode = this.user.address.postalCode;
        address.address.city = this.user.address.city;
        address.address.extra = this.user.address.extra;
        address.email = this.user.email;
        address.phoneNumber = this.user.phoneNumber;
        address.address.locationInputMode = this.user.address.locationInputMode;
    }

    private completeBillingFromUser(): void {
        this.completeFromUser(this.foAccount.billing);
    }

    private completeDeliveryFromUser(): void {
        this.foAccount.billing.address.extra = this.user.address.extra;
        this.completeFromUser(this.foAccount.delivery);
    }

    public autoCompleteBillingAddressUsingUserAddress(): void {
        this.isValidBillingAddress = !this.billingAutoComplete;
        this.billingAutoComplete = !this.billingAutoComplete;
        if (this.billingAutoComplete) {
            this.completeBillingFromUser();
            if (this.deliveryAutoCompleteBilling) {
                this.autoCompleteAdministrator();
            }
        } else {
            this.foAccount.billing = JSON.parse(JSON.stringify(newPersonAddress()));
        }
    }

    public autoCompleteAdministrator(): void {
        this.isValidDeliveryAddress = !this.deliveryAutoCompleteAdministrator;
        this.deliveryAutoCompleteAdministrator = !this.deliveryAutoCompleteAdministrator;
        if (this.deliveryAutoCompleteAdministrator) {
            this.completeDeliveryFromUser();

            this.deliveryAutoCompleteBilling = false;
        } else {
            this.foAccount.delivery = JSON.parse(JSON.stringify(newPersonAddress()));
        }
    }

    public autoCompleteBilling(): void {
        if (!this.billingAutoComplete) {
            this.isValidDeliveryAddress = !this.deliveryAutoCompleteBilling;
            this.deliveryAutoCompleteBilling = !this.deliveryAutoCompleteBilling;
            if (this.deliveryAutoCompleteBilling) {
                this.foAccount.delivery = JSON.parse(JSON.stringify(this.foAccount.billing));
                this.deliveryAutoCompleteAdministrator = false;
            } else {
                this.foAccount.delivery = JSON.parse(JSON.stringify(newPersonAddress()));
            }
        }
    }
    public changePassword() {
        this.isPasswordChange = true;
    }

    public uploadFile(files: FileList): void {
        if (files && files.length) {
            this.uploadHttpService.upload(files[0], files[0].name).subscribe(
                fileReference => this.foAccount.logoRef = fileReference.fileId,
                () => this.notificationService.error('upload.error')
            );
        }
    }

    public removeLogo(): void {
        this.foAccount.logoRef = null;
        this.logo = null;
    }

    private initCheckbox(): void {
        if (this.isRegister) {
            this.billingAutoComplete = true;
            this.deliveryAutoCompleteAdministrator = true;
            this.deliveryAutoCompleteBilling = false;
        } else {
            this.billingAutoComplete =  this.isEqualAdministrator('billing');
            this.deliveryAutoCompleteAdministrator =  this.isEqualAdministrator('delivery');
            if (!this.deliveryAutoCompleteAdministrator) {
                this.deliveryAutoCompleteBilling = this.isEqualBilling();
            }
        }
    }

    private isEqualAdministrator(state: string): boolean {
        return (this.foAccount[state].firstName === this.user.firstName &&
            this.foAccount[state].lastName === this.user.lastName &&
            this.foAccount[state].address.route === this.user.address.route &&
            this.foAccount[state].address.postalCode === this.user.address.postalCode &&
            this.foAccount[state].address.city === this.user.address.city &&
            this.foAccount[state].address.extra === this.user.address.extra &&
            this.foAccount[state].email === this.user.email &&
            this.foAccount[state].phoneNumber === this.user.phoneNumber
        );
    }
    private isEqualBilling(): boolean {
        return (this.foAccount.delivery.firstName === this.foAccount.billing.firstName &&
            this.foAccount.delivery.lastName === this.foAccount.billing.lastName &&
            this.foAccount.delivery.address.route === this.foAccount.billing.address.route &&
            this.foAccount.delivery.address.postalCode === this.foAccount.billing.address.postalCode &&
            this.foAccount.delivery.address.city === this.foAccount.billing.address.city &&
            this.foAccount.delivery.address.extra === this.foAccount.billing.extra &&
            this.foAccount.delivery.email === this.foAccount.billing.email &&
            this.foAccount.delivery.phoneNumber === this.foAccount.billing.phoneNumber
        );
    }

    private loadLogin() {
        if (!this.isRegister) {
            this.foAccountService.findFOAccount().pipe(
                tap(foAccount => this.foAccount = foAccount)
            ).subscribe(() => this.initCheckbox());
            this.invoiceHttpService.findUnpaidInvoice(this.user._id)
                .subscribe(unpaidInvoices => this.hasUnpaidInvoice = unpaidInvoices.length > 0);
        } else {
            this.initializeFOAccount();
        }
    }

    private initializeFOAccount() {
        this.foAccount = {
            foAccountRef: null,
            foAccountClientRef: null,
            manager: null,
            foRef: null,
            company: true,
            billing: {
                firstName: null,
                lastName: null,
                email: null,
                phoneNumber: null,
                address: {}
            },
            delivery: {
                firstName: null,
                lastName: null,
                email: null,
                phoneNumber: null,
                address: {}
            },
            companySiret: null,
            companyName: null,
            collectivity: false,
            chorusProConfiguration: {
                serviceNumber: null
            },
            activeStationCount: null,
            socialReason: null,
            finalClientRef: null
        };
    }

    onChangeBillingAddress(isFormValid: boolean) {
        this.isValidBillingAddress = isFormValid;
    }

    onTouchBillingAddress(isFormDirty: boolean) {
        this.isDirtyBillingAddress = isFormDirty;
        if (this.deliveryAutoCompleteBilling) {
            this.isDirtyDeliveryAddress = isFormDirty;
            this.foAccount.delivery.address = this.foAccount.billing.address;
            this.foAccount.delivery.firstName = this.foAccount.billing.firstName;
            this.foAccount.delivery.lastName = this.foAccount.billing.lastName;
            this.foAccount.delivery.email = this.foAccount.billing.email;
            this.foAccount.delivery.phoneNumber = this.foAccount.billing.phoneNumber;
        }
    }

    onChangeDeliveryAddress(isFormValid: boolean) {
        this.isValidDeliveryAddress = isFormValid;
    }

    onTouchDeliveryAddress(isFormDirty: boolean) {
        this.isDirtyDeliveryAddress = isFormDirty;
    }

    updateIsValidUserAddress(isFormValid: boolean) {
        this.isValidUserAddress = isFormValid;
    }
}

export class AccountFormValue {
    user: FleetUser;
    password?: string | Int32Array;
    foAccount?: FOAccountWrapper;
}
