import {Route} from '@angular/router';
import {AppComponent} from './app.component';
import {homeRoutes} from './home/home.route';
import {mainRoutes} from './main/main.route';
import {AuthenticatedGuard} from '../authenticated.guard';
import {TermsAndConditionsOfUseGuard} from '../termsAndConditionsOfUse.guard';
import {PackSubscriptionsConditionsGuard} from '../packSubscriptionsConditions.guard';
import {HomeGuard} from './home/home.guard';

export const appRoutes: Array<Route> = [
    {
        path: '', component: AppComponent, children: [

            // User will be redirected to home page or main page depending on two factors
            //  - if reporting isn't enabled for fo => Home page
            //  - if reporting is enabled for fo but all tabs configured for fo are unavailable
            //    due to user config (ex: station tab available but not station manager) => Home page
            //  - Otherwise => Main page
            {
                path: 'home',
                children: homeRoutes,
                canActivate: [AuthenticatedGuard, TermsAndConditionsOfUseGuard, PackSubscriptionsConditionsGuard, HomeGuard]
            },
            {
                path: 'main',
                children: mainRoutes,
                canActivate: [AuthenticatedGuard, TermsAndConditionsOfUseGuard, PackSubscriptionsConditionsGuard]
            }
        ]
    },

];
