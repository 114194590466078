import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {
    ACCEPTED_FILE_FORMATS,
    FrontEndFleetConfig, FrontMediaHttpService,
    FrontPersonHttpService,
    FrontPersonPortalContextService,
    FrontUploadHttpService,
    FrontVehicleHttpService,
    FrontVehicleVersionHttpService,
    MediaItem, MediaPublicCodeDto,
    Reporting,
    Vehicle,
    VehicleAcquisitionType,
    VehicleMakeDto,
    VehicleModelDto,
    VehicleSearchCriteria,
    VehicleUsageType,
    VehicleVersionDto
} from 'lib-front';
import {debounceTime, finalize, map, shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../services/utils/notification.service';
import {reduceFormGroupErrors} from '../../utils/formGroupUtils.service';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {FleetVehicleUtils} from '../fleetVehicleUtils';
import {cloneDeep} from 'lodash-es';
import {CurrentUserContextService} from '../../services/business/currentUserContext.service';
import {AbstractHasRoleActionComponent} from '../has-role-action/abstract-has-role-action.component';
import {carRegistrationFileExtensionValidator} from '../../directives/validators/carRegistrationFileExtensionValidator';
import {vehicleCreationUniquenessValidator} from '../../directives/validators/vehicleCreationUniquenessValidator';
import {FrontEndService} from '../../services/frontEnd.service';

@Component({
    selector: 'fleet-list-form',
    templateUrl: './fleet-list-form.component.html',
    styleUrls: ['./fleet-list-form.component.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FleetListFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FleetListFormComponent),
            multi: true
        }
    ]
})
export class FleetListFormComponent extends AbstractHasRoleActionComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
    @Input() searching: boolean;
    @Input() nbrVehicles: number;
    @Input() hasFleetWriteRole: boolean;
    @Input() public pageNumber: number;
    @Input() public nbrPageMax: number;
    @Input() public displayLimitMessage: boolean;

    @Output() readonly searchChange: EventEmitter<VehicleSearchCriteria> = new EventEmitter();
    @Output() readonly nbrVehiclesChange: EventEmitter<number> = new EventEmitter();
    @Output() readonly pageChange: EventEmitter<number> = new EventEmitter<number>();

    isInsertOpened: boolean = false;
    canEdit: boolean = true;
    criteria: VehicleSearchCriteria = { skip: 0, limit: 10 };
    vehicleDataLoaded: boolean = false;
    VehicleUsageType = VehicleUsageType;
    VehicleAcquisitionType = VehicleAcquisitionType;
    licenseNumbers = new Set();
    vehicleIdentifierNumbers = new Set();
    isRigeUser: boolean;

    form: UntypedFormGroup;
    fleetVehicleForm: UntypedFormGroup;
    vehicleVersionForm: UntypedFormGroup;
    makeCtrl: UntypedFormControl;
    licenseNumberCtrl: UntypedFormControl;
    vehicleIdentifierNumberCtrl: UntypedFormControl;
    carRegistrationDocumentCtrl: UntypedFormControl;

    get vehiclesForm(): UntypedFormArray {
        return this.form.controls['vehicles'] as UntypedFormArray;
    }

    vehicleMakesObs: Observable<VehicleMakeDto[]>;
    vehicleModelsObs: Observable<VehicleModelDto[]>;
    vehicleVersionsObs: Observable<VehicleVersionDto[]>;
    mediasCanActivateObs: Observable<MediaItem[]>;
    destroy$: Subject<void> = new Subject();

    Reporting = Reporting;
    fleetConfig: FrontEndFleetConfig;

    addingVehicle: boolean;

    vehicleMakesFilterObs: Observable<VehicleMakeDto[]>;
    vehicleModelsFilterObs: Observable<VehicleModelDto[]>;
    vehicleVersionsFilterObs: Observable<VehicleVersionDto[]>;

    foAccountRef: string;
    userId: string;

    mediasCanActivate: MediaPublicCodeDto[] = [];
    mediaCanActivateExist: boolean | undefined = undefined;
    private mediaCanActivateSubject: Subject<string> = new Subject();
    private mediaCanActivateSubscription: Subscription;

    onChangeCallback = (vehicles: Vehicle[]) => {};
    onTouchedCallback = () => {};

    constructor(private readonly fb: UntypedFormBuilder,
        private readonly vehicleHttpService: FrontVehicleHttpService,
        private readonly personHttpService: FrontPersonHttpService,
        protected readonly notificationService: NotificationService,
        private readonly vehicleVersionHttpService: FrontVehicleVersionHttpService,
        private readonly uploadHttpService: FrontUploadHttpService,
        private readonly personPortalContextService: FrontPersonPortalContextService,
        private readonly currentUserContextService: CurrentUserContextService,
        private readonly frontEndService: FrontEndService,
        private readonly mediaService: FrontMediaHttpService,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        @Inject(ACCEPTED_FILE_FORMATS) public readonly fileFormats: string[]) {
        super(notificationService);
    }

    ngOnInit(): void {
        this.route.data.pipe(
            map(data => data.detailedPerson),
        ).subscribe(detailedPerson => {
            this.userId = detailedPerson.person._id;
            this.foAccountRef = this.currentUserContextService.getCurrentFoAccountId();
            this.vehicleDataLoaded = true;
            this.personPortalContextService.isRigeUser().subscribe(isRigeUser => this.isRigeUser = isRigeUser);
        });
        this.initFormData();
        this.vehicleMakesFilterObs = this.vehicleVersionHttpService.findAllMakes().pipe(shareReplay(1));
        this.frontEndService.currentFrontEndInfo$.subscribe(frontEndInfo => this.fleetConfig = frontEndInfo?.fleetConfig);

        this.mediaCanActivateSubscription = this.mediaCanActivateSubject.pipe(
            debounceTime(200),
            switchMap((mediaCode) => this.mediaService.findLightMedias(
                {
                    foAccountId: this.foAccountRef,
                    partialPublicCode: mediaCode,
                    withDeactivated: false,
                    withoutLinkedToVehicle: true,
                    limit: 20
                }
            ))
        ).subscribe(mediaPublicCodeDtos => {
            this.mediasCanActivate = mediaPublicCodeDtos;
            if (!this.mediaCanActivateExist) {
                this.mediaCanActivateExist = this.mediasCanActivate.length > 0;
            }
        });
    }

    private initFormData() {
        this.initFleetVehicleForm();
        this.form = this.fb.group({
            licenseNumber: [''],
            vehicleIdentifierNumber: [''],
            make: [''],
            model: [''],
            version: [''],
            fleetVehicleForm: this.fleetVehicleForm,
            vehicles: this.fb.array([])
        });
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => this.onChangeCallback(value));
    }

    private initFleetVehicleForm() {
        this.makeCtrl = this.fb.control('', Validators.required);
        this.vehicleVersionForm = this.fb.group({
            make: this.makeCtrl,
            model: '',
            version: ['initial']
        });
        this.licenseNumberCtrl = this.fb.control('');
        this.vehicleIdentifierNumberCtrl = this.fb.control(null);
        this.carRegistrationDocumentCtrl = this.fb.control(null, carRegistrationFileExtensionValidator(this.fileFormats));
        this.fleetVehicleForm = this.fb.group(this.initFleetVehicleFormControls(this.vehicleVersionForm, this.licenseNumberCtrl, this.vehicleIdentifierNumberCtrl, this.carRegistrationDocumentCtrl));
        this.fleetVehicleForm.get('vehicleVersion.model').disable({emitEvent: false});
        this.fleetVehicleForm.get('vehicleVersion.version').disable({emitEvent: false});

        this.vehicleMakesObs = this.vehicleVersionHttpService.findAllMakes().pipe(shareReplay(1));

        this.makeCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.onMakeChange());
        this.fleetVehicleForm.get('vehicleVersion.model').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.onModelChange());
    }

    private initFleetVehicleFormControls(vehicleVersion: UntypedFormGroup, licenseNumber: UntypedFormControl, vehicleIdentifierNumber: UntypedFormControl, carRegistrationDocument: UntypedFormControl) {
        return {
            vehicleVersion: vehicleVersion,
            licenseNumber: licenseNumber,
            vehicleIdentifierNumber: vehicleIdentifierNumber,
            detail: '',
            vehicleUsageType: null,
            vehicleAcquisitionType: null,
            leasingPartner: '',
            media: undefined,
            priority: false,
            carRegistrationFile: carRegistrationDocument
        };
    }

    public onMakeFilterChange(make: string) {
        this.criteria.make = make === 'undefined' ? undefined : make;
        this.clearModel();
        this.clearVersion();
        this.setVehiclesModelsFilter(this.criteria.make);
    }

    public onModelFilterChange(model: string) {
        this.criteria.model = model === 'undefined' ? undefined : model;
        this.clearVersion();
        this.setVehiclesVersionsFilter(this.criteria.make, this.criteria.model);
    }

    private clearModel() {
        this.criteria.model = undefined;
    }

    private clearVersion() {
        this.criteria.version = 'initial';
    }

    private onMakeChange() {
        const make: string = this.fleetVehicleForm.get('vehicleVersion.make').value;

        this.setVehiclesModels(make).subscribe(models => {
            const model: AbstractControl = this.fleetVehicleForm.get('vehicleVersion.model');

            if (models.length && model.disabled) {
                model.enable();
                model.setValidators(Validators.required);
            } else if (!models.length) {
                model.disable();
                model.clearValidators();
            }
            model.reset();
            model.setValue('', {emitEvent: false});
        });
    }

    private onModelChange() {
        const make: string = this.fleetVehicleForm.get('vehicleVersion.make').value;
        const model: string = this.fleetVehicleForm.get('vehicleVersion.model').value;

        this.setVehiclesVersions(make, model).subscribe(versions => {
            const version: AbstractControl = this.fleetVehicleForm.get('vehicleVersion.version');

            if (versions.length && version.disabled) {
                version.enable();
            } else if (!versions.length) {
                version.disable();
                version.clearValidators();
            }

            version.reset();

            /**
             * Setting version to initial is a workaround used to be able to select standard vehicle version
             * without breaking anything in the mobile app.
             * An issue was open to do that in a better way: https://issues.4sh.fr/browse/SMBP-10790
             */
            version.setValue('initial', {emitEvent: false});
        });
    }

    private setVehiclesVersions(make: string, model: string) {
        let vehicleVersionsObs: Observable<VehicleVersionDto[]>;
        if (make && model) {
            vehicleVersionsObs = this.vehicleVersionsObs = this.vehicleVersionHttpService
                .findVersionsByMakeModel(make, model)
                .pipe(shareReplay(1));
        } else {
            vehicleVersionsObs = of([]);
        }

        return this.vehicleVersionsObs = vehicleVersionsObs;
    }

    private setVehiclesVersionsFilter(make: string, model: string) {
        let vehicleVersionsObs: Observable<VehicleVersionDto[]>;
        if (make && model) {
            vehicleVersionsObs = this.vehicleVersionsFilterObs = this.vehicleVersionHttpService
                .findVersionsByMakeModel(make, model)
                .pipe(shareReplay(1));
        } else {
            vehicleVersionsObs = of([]);
        }

        return this.vehicleVersionsFilterObs = vehicleVersionsObs;
    }

    private setVehiclesModels(make: string) {
        let vehicleModelsObs: Observable<VehicleModelDto[]>;
        if (make) {
            vehicleModelsObs = this.vehicleModelsObs = this.vehicleVersionHttpService
                .findModelsByMake(make)
                .pipe(shareReplay(1));
        } else {
            vehicleModelsObs = of([]);
        }

        return this.vehicleModelsObs = vehicleModelsObs;
    }

    private setVehiclesModelsFilter(make: string) {
        let vehicleModelsObs: Observable<VehicleModelDto[]>;
        if (make) {
            vehicleModelsObs = this.vehicleModelsFilterObs = this.vehicleVersionHttpService
                .findModelsByMake(make)
                .pipe(shareReplay(1));
        } else {
            vehicleModelsObs = of([]);
        }

        return this.vehicleModelsFilterObs = vehicleModelsObs;
    }

    private sortVehicle(vehicle1: Vehicle, vehicle2: Vehicle): number {

        // if vehicle is defined then vehicleVersion should never be null or undefined but there are some stale documents server side
        // See also https://izivia.atlassian.net/browse/IRUN-1324 (remove this check if fixed)
        if (!vehicle1 || !vehicle1.vehicleVersion) {
            return -1;
        } else if (!vehicle2 || !vehicle2.vehicleVersion) {
            return 1;
        } else {
            let compareResult: number = vehicle1.vehicleVersion.make.localeCompare(vehicle2.vehicleVersion.make);
            if (compareResult === 0) {
                compareResult = vehicle1.vehicleVersion.model
                    .localeCompare(vehicle2.vehicleVersion.model);
            }
            return compareResult;
        }
    }

    public addVehicle() {
        this.isInsertOpened = false;
        this.canEdit = true;

        const files: FileList = this.fleetVehicleForm.get('carRegistrationFile').value;
        if (files && files.length) {
            this.addingVehicle = true;
            this.uploadHttpService.upload(files[0], files[0].name)
                .subscribe(
                    fileReference => {
                        this.fleetVehicleForm.get('carRegistrationFile').setValue(
                            {id: fileReference.fileId,
                                name: files[0].name
                            });
                        this.addVehicleForm();
                    },
                    () => {
                        this.notificationService.error('upload.error');
                        this.addingVehicle = false;
                    }
                );
        } else {
            this.addVehicleForm();
        }
    }

    private addVehicleForm() {
        const vehicleFormToAdd: UntypedFormGroup = cloneDeep(this.fleetVehicleForm);
        this.saveFleet(vehicleFormToAdd);
        this.resetFormInitialValues();
    }

    private saveFleet(controlToAdd: UntypedFormGroup) {
        this.addingVehicle = true;
        this.vehicleHttpService.createVehicle(controlToAdd.value)
            .pipe(
                finalize(() => this.addingVehicle = false)
            )
            .subscribe((vehicle) => {
                this.notificationService.success('fleet.create.default');

                const formControl = this.fb.control(vehicle);
                if (controlToAdd.value.media) {
                    this.vehicleHttpService.addMediaToVehicle(vehicle.id, controlToAdd.value.media.mediaId)
                        .subscribe(() => {
                            formControl.value.mediaUsages = [controlToAdd.value.media];
                            this.addItemForm(formControl);
                            this.sortForm();
                        },
                        () => {
                            this.notificationService.error('fleet.vehicle.media.error');
                        });
                } else {
                    this.addItemForm(formControl);
                    this.sortForm();
                }

                this.licenseNumbers.add(vehicle.licenseNumber.toLowerCase());
                this.vehicleIdentifierNumbers.add(vehicle.vehicleIdentifierNumber.toLowerCase());
                this.updateNumberVehicles(this.nbrVehicles + 1);
                this.resetSearch();
            }, () => {
                this.notificationService.error('fleet.error');
            });
    }

    public addItemForm(control?: AbstractControl) {
        if (control) {
            this.vehiclesForm.push(control);
        }
        this.ref.detectChanges();
        this.form.updateValueAndValidity();
    }

    public updateNumberVehicles(nbrVehicles: number) {
        this.nbrVehiclesChange.emit(nbrVehicles);
    }

    public cancelAddVehicle() {
        this.isInsertOpened = false;
        this.canEdit = true;
        this.fleetVehicleForm.reset();
        this.resetFormInitialValues();
    }

    private resetFormInitialValues() {
        this.fleetVehicleForm.reset();
        this.fleetVehicleForm.get('vehicleUsageType').setValue(null);
        this.fleetVehicleForm.get('vehicleAcquisitionType').setValue(null);
        this.fleetVehicleForm.get('vehicleVersion.make').setValue('');
        this.fleetVehicleForm.get('vehicleVersion.model').setValue('');
        this.fleetVehicleForm.get('vehicleVersion.version').setValue('');
        this.fleetVehicleForm.get('media').setValue(undefined);
    }

    public deleteVehicle(vehicle: UntypedFormControl) {
        this.vehicleHttpService.deleteVehicle(vehicle.value.id)
            .subscribe(() => {
                this.notificationService.success('fleet.delete');
                this.vehiclesForm.removeAt(this.vehiclesForm.controls.findIndex(item => vehicle.value.id === item.value.id));
                this.updateNumberVehicles(this.nbrVehicles - 1);
                this.licenseNumbers.delete(vehicle.value.licenseNumber.toLowerCase());
                this.vehicleIdentifierNumbers.delete(vehicle.value.vehicleIdentifierNumber.toLowerCase());
                this.resetSearch();
                this.sortForm();
            }, () => {
                this.notificationService.error('fleet.error');
            });
    }

    public updateVehicle(vehicle: Vehicle) {
        this.vehicleHttpService.updateVehicle(vehicle.id, vehicle)
            .subscribe(() => {
                this.notificationService.success('fleet.update');

                if (!!vehicle.mediaId) {
                    this.vehicleHttpService.addMediaToVehicle(vehicle.id, vehicle.mediaId)
                        .subscribe(() => {
                            this.updateVehicleForm(vehicle);
                        });
                } else {
                    this.vehicleHttpService.removeMediaFromVehicle(vehicle.id, vehicle.mediaId)
                        .subscribe(() => {
                            this.updateVehicleForm(vehicle);
                        });
                }

                this.licenseNumbers.add(vehicle.licenseNumber.toLowerCase());
                this.vehicleIdentifierNumbers.add(vehicle.vehicleIdentifierNumber.toLowerCase());
                this.resetSearch();
                this.sortForm();
            }, () => {
                this.notificationService.error('fleet.error');
            });
    }

    private updateVehicleForm(vehicle: Vehicle) {
        let vehicleFormToUpdate = this.vehiclesForm.value.find(vehicleForm => vehicleForm.id === vehicle.id);
        vehicleFormToUpdate.vehicleVersion = vehicle.vehicleVersion;
        vehicleFormToUpdate.priority = vehicle.priority;
        vehicleFormToUpdate.licenseNumber = vehicle.licenseNumber;
        vehicleFormToUpdate.vehicleIdentifierNumber = vehicle.vehicleIdentifierNumber;
        vehicleFormToUpdate.detail = vehicle.detail;
        vehicleFormToUpdate.carRegistrationFile = vehicle.carRegistrationFile;
        vehicleFormToUpdate.vehicleUsageType = vehicle.vehicleUsageType;
        vehicleFormToUpdate.vehicleAcquisitionType = vehicle.vehicleAcquisitionType;
        vehicleFormToUpdate.leasingPartner = vehicle.leasingPartner;
        vehicleFormToUpdate.mediaUsages = vehicle.mediaUsages;
    }

    public canEditVehicle(canEdit: boolean) {
        this.canEdit = canEdit;
    }

    public sortForm() {
        this.vehiclesForm.controls = this.vehiclesForm.controls.sort(
            (vehicle1, vehicle2) => this.sortVehicle(vehicle1.value, vehicle2.value));
    }

    public resetSearch(): void {
        this.form.controls['licenseNumber'].setValue('');
        this.form.controls['vehicleIdentifierNumber'].setValue('');
        this.form.controls['make'].setValue(undefined);
        this.form.controls['model'].setValue(undefined);
        this.form.controls['version'].setValue('initial');
        this.updateCriteria();
    }

    public searchFleet() {
        this.updateCriteria();
        this.searchChange.emit(Object.assign({}, this.criteria));
    }

    private updateCriteria() {
        this.criteria.licenseNumber = this.form.get('licenseNumber').value;
        this.criteria.vehicleIdentifierNumber = this.form.get('vehicleIdentifierNumber').value;
        this.criteria.make = this.form.get('make').value;
        this.criteria.model = this.form.get('model').value;
        this.criteria.version = this.form.get('version').value;
    }

    public openFleetVehicleForm() {
        this.doActionIfHasRole(
            () => {
                this.fleetVehicleForm.value.media = undefined;
                this.searchMedias(null);
                this.licenseNumberCtrl.setValidators([Validators.required,
                    vehicleCreationUniquenessValidator('licenseNumber', this.vehiclesForm)]);
                this.vehicleIdentifierNumberCtrl.setValidators([Validators.maxLength(17),
                    vehicleCreationUniquenessValidator('vehicleIdentifierNumber', this.vehiclesForm)]);
                this.isInsertOpened = true;
                this.canEdit = false;
            },
            this.hasFleetWriteRole
        );
    }

    public trackByMediaId(index: number, media: MediaItem) {
        return media?.id ?? index;
    }

    public trackByName(index: number, vehicle: VehicleMakeDto | VehicleModelDto) {
        return vehicle?.name ?? index;
    }

    public trackByVehicleVersionId(index: number, vehicleVersion: VehicleVersionDto) {
        return vehicleVersion?.id ?? index;
    }

    public sortNull() {
        return FleetVehicleUtils.sortNull();
    }

    public validate(c: AbstractControl): ValidationErrors | null {
        return this.form.invalid ? {'fleet-vehicule-list': reduceFormGroupErrors(this.form)} : null;
    }

    public writeValue(vehicles: Vehicle[]): void {
        if (this.vehiclesForm.length) {
            while (this.vehiclesForm.length) {
                this.vehiclesForm.removeAt(0);
            }
        }
        this.ref.detectChanges();

        if (vehicles && vehicles.length) {
            vehicles
                .sort((vehicle1, vehicle2) => this.sortVehicle(vehicle1, vehicle2))
                .forEach(vehicleForm => {
                    this.licenseNumbers.add(vehicleForm.licenseNumber.toLowerCase());
                    this.vehicleIdentifierNumbers.add(vehicleForm.vehicleIdentifierNumber.toLowerCase());
                    const formControl = this.fb.control(vehicleForm);
                    this.addItemForm(formControl);
                });
        }
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    public searchMedias(mediaCode: string) {
        this.mediaCanActivateSubject.next(mediaCode);
    }

    ngOnDestroy(): void {
        this.mediaCanActivateSubscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    protected readonly undefined = undefined;
}
