import {AccountInformationsComponent} from './informations/account-informations.component';
import {AddressFormComponent} from './address-form/address-form.component';
import {ChargeRecordDetailsComponent} from './charge-record-details/charge-record-details.component';
import {ChargeRecordChartComponent} from './charge-record-chart/charge-record-chart.component';
import {CollaboratorFormComponent} from './collaborator-form/collaborator-form.component';
import {CollaboratorListFormComponent} from './collaborator-list-form/collaborator-list-form.component';
import {CommonModule} from '@angular/common';
import {DatePickerComponent} from './datePicker/datePicker.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import {FleetListFormComponent} from './fleet-list-form/fleet-list-form.component';
import {FleetVehicleFormComponent} from './fleet-form/fleet-vehicle-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IndicatorComponent} from './indicator/indicator.component';
import {InputNumberButtonComponent} from './input-number-button/input-number-button.component';
import {MediaOrderComponent} from './media-order/media-order.component';
import {NextLastPageComponent} from './next-last-page/next-last-page.component';
import {NgModule} from '@angular/core';
import {PaymentOptionsComponent} from './payment-options/payment-options.component';
import {PaymentTypeComponent} from './payment-type/payment-type.component';
import {PipesModule} from '../pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {StepComponent} from './step-indicator/step/step.component';
import {StepIndicatorComponent} from './step-indicator/step-indicator.component';
import {SubscriptionMediaOrderComponent} from './subscription-media-order/subscription-media-order.component';
import {SubscriptionOfferViewComponent} from './subscription-offer-view/subscription-offer-view.component';
import {SubscriptionOptionsBlocksComponent} from './subscription-options-blocks/subscription-options-blocks.component';
import {SubscriptionOptionsComponent} from './subscription-options/subscription-options.component';
import {
    SubscriptionOptionsResultViewComponent
} from './subscription-options-result-view/subscription-options-result-view.component';
import {SubscriptionOptionsViewComponent} from './subscription-options-view/subscription-options-view.component';
import {SubscriptionPackChoiceComponent} from './subscription-pack-choice/subscription-pack-choice.component';
import {SwitchComponent} from './switch/switch.component';
import {TabComponent} from './tabs/tab.component';
import {TabsRoundComponent} from './tabs-round/tabs-round.component';
import {TabRoundComponent} from './tabs-round/tab-round.component';
import {TabsComponent} from './tabs/tabs.component';
import {TranslateModule} from '@ngx-translate/core';
import {InputFileComponent} from './input-file/input-file.component';
import {ImportExportComponent} from './import-export/import-export.component';
import {SuggestComponent} from './suggest/suggest.component';
import {SubscriptionProgressBarComponent} from './subscription-progress-bar/subscription-progress-bar.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {ChargeAreaComponent} from './charge-area/charge-area.component';
import {ChargeAreaIndicatorComponent} from './charge-area-indicator/charge-area-indicator.component';
import {InfraStatusIndicatorComponent} from './station-status-indicator/station-status-indicator.component';
import {ChargeAreaStationComponent} from './charge-area-station/charge-area-station.component';
import {CollapsibleCardComponent} from './collapse-card/collapsible-card.component';
import {CollapsibleCardDetailComponent} from './collapse-card-detail/collapsible-card-detail.component';
import {StationCardComponent} from './station-card/station-card.component';
import {ChargePointsCardComponent} from './charge-points-card/charge-points-card.component';
import {StationTariffCardComponent} from './sation-tariff-card/station-tariff-card.component';
import {ChargeRecordTableComponent} from './charge-record-table/charge-record-table.component';
import {StationEditComponent} from './station-edit/station-edit.component';
import {ChargePointActionsComponent} from './charge-point-actions/charge-point-actions.component';
import {PlugRemoteAction} from './plug-remote-action/plug-remote-action.component';
import {StationRemoteAction} from './station-remote-action/station-remote-action.component';
import {ChargeAreaEditComponent} from './charge-area-edit/charge-area-edit.component';
import {OpeningDayListSelectorComponent} from './opening-day-list-selector/opening-day-list-selector.component';
import {OpeningDayEditComponent} from './opening-day-edit/opening-day-edit.component';
import {
    ChargeAreaGeolocationEditComponent
} from './charge-area-geolocation-edit/charge-area-geolocation-edit.component';
import {TariffCardComponent} from './tariff-card/tariff-card.component';
import {TariffFormComponent} from './tariff-form/tariff-form.component';
import {TariffElementEditComponent} from './tariff-element-edit/tariff-element-edit.component';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {NgSelectModule} from '@ng-select/ng-select';
import {DirectivesModule} from '../directives/directives.module';
import {ContactCardComponent} from './contact-card/contact-card.component';
import {EnvelopeComponent} from './envelope/envelope.component';
import {CollapsibleStationsSelectComponent} from './collapsible-stations-select/collapsible-stations-select.component';
import {
    CollapsibleStationsSelectsComponent
} from './collapsible-stations-selects/collapsible-stations-selects.component';
import {StationTariffLinksComponent} from './station-tariff-links/station-tariff-links.component';
import {TariffStationUsageComponent} from './tariff-station-usage/tariff-station-usage.component';
import {ChargeAreaFilterComponent} from './charge-area-filter/charge-area-filter.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {ClipboardModule} from 'ngx-clipboard';
import {TariffChargeSimulatorComponent} from './tariff-charge-simulator/tariff-charge-simulator.component';
import {NotificationComponent} from './notification/notification.component';
import {ChargeTabComponent} from '../views/app/main/chargeConsumptions/chargeTab/chargeTab.component';
import {MomentModule} from 'ngx-moment';
import {SiretInputComponent} from './siret-input/siret-input.component';
import {VehicleVersionSelectorComponent} from './vehicle-version-selector/vehicle-version-selector.component';
import {
    AdditionalInformationFieldComponent
} from './additional-information-field/additional-information-field.component';
import {AdditionalInformationComponent} from './additional-information/additional-information.component';
import {LibFrontModule} from 'lib-front';
import {FaqIconComponent} from './faq/faq-icon/faq-icon.component';
import {FaqPageComponent} from './faq/faq-page/faq-page.component';
import {FaqFileComponent} from './faq/faq-file/faq-file.component';
import {FinancialReportSearchComponent} from './financial-report-search/financial-report-search.component';
import {FinancialReportTableComponent} from './financial-report-table/financial-report-table.component';
import {FaqBreadcrumbsComponent} from './faq/faq-breadcrumbs/faq-breadcrumbs.component';
import {IndemnityFormComponent} from './indemnity-form/indemnity-form.component';
import {IndemnityCardComponent} from './indemnity-card/indemnity-card.component';
import {ConsumptionSearchComponent} from './consumptions/consumption-search/consumption-search.component';
import {ConsumptionTableComponent} from './consumptions/consumption-table/consumption-table.component';
import {IziviaPopupComponent} from './izivia-popup/izivia-popup.component';
import {SubscriptionPackItemComponent} from './subscription-pack-item/subscription-pack-item.component';
import {
    SubscriptionPackItemDetailComponent
} from './subscription-pack-item-detail/subscription-pack-item-detail.component';
import {PromoTokenInputComponent} from './promoToken/promo-token-input.component';
import {
    PromoSubscriptionOfferViewComponent
} from './subscription-offer-view/promo-subscription-offer-view/promo-subscription-offer-view.component';
import {
    StationReportIncidentPopupComponent
} from './station-report-incident-popup/station-report-incident-popup.component';
import {RejectExpenseReportPopupComponent} from './reject-expense-report-popup/reject-expense-report-popup.component';
import {AddPaymentPopupComponent} from './add-payment-popup/add-payment-popup.component';
import {TimePickerComponent} from './time-picker/time-picker.component';
import {PasswordValidationErrorsComponent} from './password-validation-errors/password-validation-errors.component';
import {FleetCalendarComponent} from './fleet-calendar/fleet-calendar.component';
import {FleetPlanningComponent} from './fleet-planning/fleet-planning.component';
import {CalendarDateFormatter, CalendarModule, DateAdapter} from 'angular-calendar';
import moment from 'moment';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import {FleetChargingProgramComponent} from './fleet-charging-program/fleet-charging-program.component';
import {
    FleetChargingProgramDeleteComponent
} from './fleet-charging-program/fleet-charging-program-delete/fleet-charging-program-delete.component';
import {
    FleetChargingProgramUpdateComponent
} from './fleet-charging-program/fleet-charging-program-update/fleet-charging-program-update.component';
import {FleetTimePickerComponent} from './fleet-time-picker/fleet-time-picker.component';
import {MediaOrderPromoComponent} from './media-order-promo/media-order-promo.component';
import {MediaOrderPriceInfoComponent} from './media-order-price-info/media-order-price-info.component';
import {ShowHidePasswordInputComponent} from './show-hide-password-input/show-hide-password-input.component';
import {FoAccountSwitcherComponent} from './fo-account-switcher/fo-account-switcher.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {IntPhoneNumberInputComponent} from './int-phone-number-input/int-phone-number-input.component';
import {ManualAddressFormComponent} from './address-form-wrapper/manual-address-form/manual-address-form.component';
import {FleetCalendarUtils} from './fleetCalendarUtils';
import {
    AcceptTermsAndConditionsPopupComponent
} from './accept-terms-and-conditions-popup/accept-terms-and-conditions-popup.component';
import {ManagerRolesFormComponent} from './manager-roles-form/manager-roles-form.component';
import {RoleSelectComponent} from './role-select/role-select.component';
import {
    AcceptPackSubscriptionsConditionsComponent
} from './accept-pack-subscriptions-conditions/accept-pack-subscriptions-conditions.component';
import {ConsumptionTabComponent} from '../views/app/main/chargeConsumptions/consumptionTab/consumptionTab.component';
import {ConsumptionDetailsComponent} from './consumption-details/consumption-details.component';
import {
    ChargeConsumptionDetailsComponent
} from './consumption-details/charge-consumption-details/charge-consumption-details.component';
import {
    ReservationConsumptionDetailsComponent
} from './consumption-details/reservation-consumption-details/reservation-consumption-details.component';
import {
    ConsumptionDetailsDataComponent
} from './consumption-details/consumption-details-data/consumption-details-data.component';
import {FleetSpinnerComponent} from './ui/spinner/fleet-spinner.component';
import {TotalConsumptionTableComponent} from './consumptions/total-consumption-table/total-consumption-table.component';
import {
    OtpConsumptionDetailsComponent
} from './consumption-details/otp-consumption-details/otp-consumption-details.component';
import {
    ServiceConsumptionDetailsComponent
} from './consumption-details/service-consumption-details/service-consumption-details.component';
import {
    TariffModificationDropdownComponent
} from './tariff-modification-dropdown/tariff-modification-dropdown.component';
import {
    TariffModificationPopupComponent
} from './tariff-date-picker-popup/tariff-modification-popup.component';
import {HelpfulDocumentDetailComponent} from './helpful-document-detail/helpful-document-detail.component';
import {
    AutomaticAddressSuggestionFormComponent
} from './address-form-wrapper/automatic-address-suggestion-form/automatic-address-suggestion-form.component';
import {AddressFormWrapperComponent} from './address-form-wrapper/address-form-wrapper.component';
import {ReplacePaymentPopupComponent} from './replace-payment-popup/replace-payment-popup.component';

export function momentAdapterFactory() {
    return adapterFactory(moment);
}

const components = [
    AcceptPackSubscriptionsConditionsComponent,
    AcceptTermsAndConditionsPopupComponent,
    AccountInformationsComponent,
    AddressFormComponent,
    ManualAddressFormComponent,
    AutomaticAddressSuggestionFormComponent,
    ChargeConsumptionDetailsComponent,
    ChargeRecordDetailsComponent,
    ChargeRecordChartComponent,
    CollaboratorFormComponent,
    CollaboratorListFormComponent,
    ConsumptionDetailsComponent,
    ConsumptionDetailsDataComponent,
    DatePickerComponent,
    EnvelopeComponent,
    FleetListFormComponent,
    FoAccountSwitcherComponent,
    FleetVehicleFormComponent,
    FleetPlanningComponent,
    FleetCalendarComponent,
    FleetChargingProgramComponent,
    FleetChargingProgramDeleteComponent,
    FleetChargingProgramUpdateComponent,
    IndicatorComponent,
    InputFileComponent,
    PasswordValidationErrorsComponent,
    InputNumberButtonComponent,
    ShowHidePasswordInputComponent,
    ImportExportComponent,
    MediaOrderComponent,
    NextLastPageComponent,
    PaymentOptionsComponent,
    PaymentTypeComponent,
    ProgressBarComponent,
    StationCardComponent,
    ChargePointsCardComponent,
    StationTariffCardComponent,
    FinancialReportSearchComponent,
    FinancialReportTableComponent,
    StepComponent,
    ChargeRecordTableComponent,
    StepIndicatorComponent,
    SubscriptionMediaOrderComponent,
    SubscriptionOfferViewComponent,
    SubscriptionOptionsBlocksComponent,
    SubscriptionOptionsComponent,
    SubscriptionOptionsResultViewComponent,
    SubscriptionOptionsViewComponent,
    SubscriptionProgressBarComponent,
    SubscriptionPackChoiceComponent,
    PromoSubscriptionOfferViewComponent,
    SuggestComponent,
    SwitchComponent,
    TabComponent,
    TabsComponent,
    TabsRoundComponent,
    TabRoundComponent,
    ManagerRolesFormComponent,
    ChargeAreaIndicatorComponent,
    ChargeAreaComponent,
    InfraStatusIndicatorComponent,
    ChargeAreaStationComponent,
    CollapsibleCardComponent,
    CollapsibleCardDetailComponent,
    StationEditComponent,
    ChargePointActionsComponent,
    MediaOrderPromoComponent,
    MediaOrderPriceInfoComponent,
    PlugRemoteAction,
    StationRemoteAction,
    ChargeAreaEditComponent,
    OpeningDayListSelectorComponent,
    OpeningDayEditComponent,
    OtpConsumptionDetailsComponent,
    ChargeAreaGeolocationEditComponent,
    TariffCardComponent,
    TariffFormComponent,
    TariffElementEditComponent,
    ContactCardComponent,
    ReservationConsumptionDetailsComponent,
    RoleSelectComponent,
    CollapsibleStationsSelectComponent,
    CollapsibleStationsSelectsComponent,
    StationTariffLinksComponent,
    TariffStationUsageComponent,
    ChargeAreaFilterComponent,
    NotificationComponent,
    ChargeTabComponent,
    ConsumptionTabComponent,
    TariffChargeSimulatorComponent,
    ServiceConsumptionDetailsComponent,
    SiretInputComponent,
    VehicleVersionSelectorComponent,
    AdditionalInformationFieldComponent,
    AdditionalInformationComponent,
    FaqIconComponent,
    FaqPageComponent,
    FaqFileComponent,
    FaqBreadcrumbsComponent,
    IndemnityFormComponent,
    IndemnityCardComponent,
    IndemnityFormComponent,
    FaqBreadcrumbsComponent,
    ConsumptionSearchComponent,
    ConsumptionTableComponent,
    IziviaPopupComponent,
    SubscriptionPackItemComponent,
    SubscriptionPackItemDetailComponent,
    PromoTokenInputComponent,
    StationReportIncidentPopupComponent,
    RejectExpenseReportPopupComponent,
    AddPaymentPopupComponent,
    TimePickerComponent,
    FleetTimePickerComponent,
    IntPhoneNumberInputComponent,
    FleetSpinnerComponent,
    TotalConsumptionTableComponent,
    TariffModificationDropdownComponent,
    TariffModificationPopupComponent,
    HelpfulDocumentDetailComponent,
    AddressFormWrapperComponent,
    ReplacePaymentPopupComponent
];

@NgModule({
    exports: components,
    declarations: components,
    imports: [
        CommonModule,
        DpDatePickerModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        NgxSliderModule,
        NgSelectModule,
        DirectivesModule,
        GoogleMapsModule,
        GooglePlaceModule,
        ClipboardModule,
        MomentModule,
        PipesModule,
        CalendarModule.forRoot(
            {
                provide: DateAdapter,
                useFactory: momentAdapterFactory,
            },
            {
                dateFormatter: {
                    provide: CalendarDateFormatter,
                    useClass: FleetCalendarUtils
                }
            }
        ),
        NgxIntlTelInputModule,
        LibFrontModule
    ]
})
export class ComponentsModule {
}
