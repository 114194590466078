import {NgModule} from '@angular/core';
import {AppRootComponent} from './appRoot.component';
import {RouterModule} from '@angular/router';
import {AppRootHeaderComponent} from './appRootHeader.component';
import {appRoutes} from './app/app.route';
import {AppModule} from './app/app.module';
import {LoginModule} from './login/login.module';
import {loginRoutes} from './login/login.route';
import {AuthenticatedGuard} from './authenticated.guard';
import {TranslateModule} from '@ngx-translate/core';
import {CurrentUserResolver} from '../resolver/currentUser.resolver';
import {registerRoutes} from './register/register.route';
import {RegisterModule} from './register/register.module';
import {styleGuideRoutes} from './styleGuide/style-guide.route';
import {StyleGuideModule} from './styleGuide/style-guide.module';
import {CommonModule} from '@angular/common';
import {SuspendedAccountGuard} from './suspendedAccount.guard';
import {ComponentsModule} from '../components/components.module';
import {CurrentUserContextResolver} from '../resolver/currentUserContext.resolver';
import {TermsAndConditionsOfUseGuard} from './termsAndConditionsOfUse.guard';
import {BlockingInvoicesGuard} from './app/blockingInvoices.guard';
import {FirstBlockingInvoiceGuard} from './firstBlockingInvoice.guard';
import {PackSubscriptionsConditionsGuard} from './packSubscriptionsConditions.guard';
import {DirectivesModule} from '../directives/directives.module';
import {AllowToSignUpGuard} from './allowToSignUp.guard';
import {theme} from '../../theme';

@NgModule({
    declarations: [
        AppRootComponent,
        AppRootHeaderComponent
    ],
    imports: [
        RouterModule.forRoot([
            {path: '', redirectTo: '/home', pathMatch: 'full'},
            {
                path: '', children: appRoutes, canActivate: [AuthenticatedGuard, TermsAndConditionsOfUseGuard, FirstBlockingInvoiceGuard],
                resolve: {user: CurrentUserResolver, userContext: CurrentUserContextResolver}, runGuardsAndResolvers: 'always',
                title: theme.title
            },
            {path: 'login', children: loginRoutes, title: theme.title},
            {path: 'register', children: registerRoutes, canActivate: [AllowToSignUpGuard], title: theme.title},
            {path: 'styleGuide', children: styleGuideRoutes, title: theme.title},
            {path: '**', redirectTo: '/home'}
        ],
        { useHash: true, paramsInheritanceStrategy: 'always' }),
        AppModule,
        TranslateModule,
        LoginModule,
        RegisterModule,
        StyleGuideModule,
        CommonModule,
        ComponentsModule,
        DirectivesModule
    ],
    providers: [
        AuthenticatedGuard,
        TermsAndConditionsOfUseGuard,
        FirstBlockingInvoiceGuard,
        BlockingInvoicesGuard,
        SuspendedAccountGuard,
        PackSubscriptionsConditionsGuard
    ],
    exports: [AppRootComponent]
})
export class ViewsModule {
}
