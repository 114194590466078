import {NgModule} from '@angular/core';
import {ConfigurationComponent} from './configuration.component';
import {CommonModule} from '@angular/common';
import {FleetConfigurationComponent} from './fleet/fleet-configuration.component';
import {PermissionConfigurationComponent} from './permission/permission-configuration.component';
import {ComponentsModule} from '../../../../components/components.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {CollaboratorConfigurationComponent} from './collaborator/collaborator-configuration.component';
import {FleetGuard} from './fleet.guard';
import {AccountConfigurationComponent} from './account/configuration-account.component';
import {TariffConfigurationComponent} from './tariff/tariff-configuration.component';
import {SupportComponent} from './support/support.component';
import {FaqComponent} from './faq/faq.component';
import {IndemnityConfigurationComponent} from './indemnity/indemnity-configuration.component';
import {RouterModule} from '@angular/router';
import {DreevProviderGuard} from '../../../dreevProvider.guard';
import {ExpenseReportModule} from '../expenseReport/expense-report.module';
import {NotificationConfigurationComponent} from './notifications/notification-configuration.component';
import {FleetReadRoleGuard} from './fleet-read-role.guard';
import {CollaboratorsReadRoleGuard} from './collaborators-read-role.guard';
import {SubscriberRolesReadRoleGuard} from './subscriber-roles-read-role.guard';
import {TariffGuard} from './tariff.guard';
import {DirectivesModule} from '../../../../directives/directives.module';
import {MomentModule} from 'ngx-moment';
import {PipesModule} from '../../../../pipes/pipes.module';
import {SmartChargingComponent} from './smart-charging/smart-charging.component';
import {HelpfulDocumentsComponent} from './helpfulDocument/helpfulDocuments.component';


@NgModule({
    declarations: [
        ConfigurationComponent,
        AccountConfigurationComponent,
        FleetConfigurationComponent,
        CollaboratorConfigurationComponent,
        PermissionConfigurationComponent,
        NotificationConfigurationComponent,
        TariffConfigurationComponent,
        SupportComponent,
        FaqComponent,
        IndemnityConfigurationComponent,
        SmartChargingComponent,
        HelpfulDocumentsComponent
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        TranslateModule,
        FormsModule,
        RouterModule,
        ExpenseReportModule,
        DirectivesModule,
        MomentModule,
        PipesModule
    ],
    providers: [
        FleetGuard,
        DreevProviderGuard,
        FleetReadRoleGuard,
        CollaboratorsReadRoleGuard,
        SubscriberRolesReadRoleGuard,
        TariffGuard
    ]
})
export class ConfigurationModule {

}
