import {Route} from '@angular/router';
import {ConfigurationComponent} from './configuration.component';
import {FleetGuard} from './fleet.guard';
import {FleetPlanningComponent} from '../../../../components/fleet-planning/fleet-planning.component';
import {FleetCalendarComponent} from '../../../../components/fleet-calendar/fleet-calendar.component';
import {DreevProviderGuard} from '../../../dreevProvider.guard';
import {FleetReadRoleGuard} from './fleet-read-role.guard';
import {CollaboratorsReadRoleGuard} from './collaborators-read-role.guard';
import {SubscriberRolesReadRoleGuard} from './subscriber-roles-read-role.guard';
import {TariffGuard} from './tariff.guard';
import {SmartChargingComponent} from './smart-charging/smart-charging.component';

export const configurationRoutes: Array<Route> = [
    {path: '', redirectTo: 'account', pathMatch: 'full'},
    {path: 'account', component: ConfigurationComponent},
    {path: 'fleet', component: ConfigurationComponent, canActivate: [FleetGuard, FleetReadRoleGuard], runGuardsAndResolvers: 'always'},
    {
        path: 'fleet/planning', component: FleetPlanningComponent, canActivate: [DreevProviderGuard], children: [
            {path: ':vehicleId', component: FleetCalendarComponent}
        ]
    },
    {path: 'collaborators', canActivate: [CollaboratorsReadRoleGuard], component: ConfigurationComponent},
    {path: 'permission', canActivate: [SubscriberRolesReadRoleGuard], component: ConfigurationComponent},
    {path: 'notifications', component: ConfigurationComponent},
    {path: 'support', component: ConfigurationComponent},
    {path: 'tariff', canActivate: [TariffGuard], component: ConfigurationComponent},
    {path: 'tariff/:tariffId', canActivate: [TariffGuard], component: ConfigurationComponent},
    // SMBP-12380 : disabled tariff edition
    // {path: 'tariff/:tariffId/edit', component: ConfigurationComponent},
    {path: 'indemnity', component: ConfigurationComponent},
    {path: 'faq', component: ConfigurationComponent},
    {path: 'smart-charging', component: SmartChargingComponent, canActivate: [DreevProviderGuard]},
    {path: 'helpfulDocuments', component: ConfigurationComponent}
];
